import { TextMessageCampaignVerificationStatus } from "type/text-message-campaign-verification-status";
import * as Mui from "@material-ui/core";
import Alert, { Color } from '@material-ui/lab/Alert';
import React from 'react';

interface Props {
	status?: TextMessageCampaignVerificationStatus;
}

const Component: React.FC<Props> = ({ status }) => {
	if (!status) {
		return null;
	}

	const getSeverity = (status: TextMessageCampaignVerificationStatus): Color => {
		if (status === TextMessageCampaignVerificationStatus.VERIFIED) {
			return "success";
		} else {
			return "warning";
		}
	};

	const getInfo = (status: TextMessageCampaignVerificationStatus): { label: string; description: string; } => {
		if (status === TextMessageCampaignVerificationStatus.VERIFIED) {
			return {
				label: "Campaign Approved",
				description: "Your registration is approved! Texts will now start sending from your account."
			};
		} else {
			return {
				label: "Approval Pending",
				description: "Our texting provider, Twilio, is reviewing your registration. No messages will be sent until approval is complete. This typically takes a few weeks. Check back here later and look for an 'Approved' status to start sending texts!"
			};
		}
	};

	return (
		<Alert
			variant="standard"
			severity={getSeverity(status)}
		>
			<Mui.Typography variant="h2">
				{getInfo(status).label}
			</Mui.Typography>
			<Mui.Typography>
				{getInfo(status).description}
			</Mui.Typography>
		</Alert>
	);
};

export const CampaignVerificationStatus = Component;