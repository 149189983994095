import React from "react"
import * as Mui from "@material-ui/core";
import { getPayload, RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Market } from "model/market";
import { getLeads } from "redux/selector";
import * as Icons from "react-feather";
import { styles } from "./styles";
import { SubscriptionsTable } from "./subscriptions-table";
import { updateMarketSubscriptionsNoLoading as updateMarketSubscriptions } from "redux/slice/market-report-subscriptions";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { FindSelect } from "component/shared/find-select";
import { MarketReportType } from "type/market-report-type";
import { SaveButton } from "component/shared/save-button";
import { fetchLeads, createLead } from "redux/slice/leads";
import { LeadStatus } from "type/lead-status";

const mapStateToProps = (state: RootState) => {
	const leads = getLeads(state).filter(lead => lead.status.active);
	return {
		leads,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarketSubscriptions,
	createLead,
	fetchLeads,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	market: Market,
}

interface State {
	term: string;
	listingReport: boolean;
	openHomeReport: boolean;
	marketReport: boolean;
	selectedLeads: {id?: number | string, value: string}[];
	resetSelect: boolean;
	submitted: boolean;
	saving: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
			listingReport: true,
			openHomeReport: true,
			marketReport: true,
			selectedLeads: [],
			resetSelect: false,
			submitted: false,
			saving: false,
		};
	}

	private async save(subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[]) {
		this.setState({saving: true});
		await this.props.updateMarketSubscriptions({market: this.props.market, subscriptions});
		this.setState({saving: false, resetSelect: true});
	}

	private async add() {
		const { leads, market, createLead } = this.props;
		const { selectedLeads, listingReport, marketReport, openHomeReport } = this.state;
		const subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[] = market.marketReportSubscriptions ? [...market.marketReportSubscriptions] : [];
		for (const selected of selectedLeads) {
			const leadId = selected.id as number
			let lead = leads.find(lead => {
				if (leadId) {
					return lead.id === leadId;
				} else {
					return lead.email === selected.value;
				}
			});
			if (!lead) {
				const leadResource = await getPayload(await createLead({lead:{
					email: selected.value,
					status: LeadStatus.ACTIVE,
					label: selected.value,
				}}));
				lead = this.props.leads.find(lead => lead.id === leadResource.id);
			}
			if (lead) {
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.LISTING);
					if (i >= 0) {
						subscriptions[i].inactive = !listingReport;
					} else if (lead && market.listingReport && market.listingReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.listingReport,
							report: market.listingReport,
						});
					}
				}
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.OPEN_HOMES);
					if (i >= 0) {
						subscriptions[i].inactive = !openHomeReport;
					} else if (lead && market.openHomesReport && market.openHomesReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.openHomeReport,
							report: market.openHomesReport,
						});
					}
				}
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.MARKET);
					if (i >= 0) {
						subscriptions[i].inactive = !marketReport;
					} else if (lead && market.marketReport && market.marketReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.marketReport,
							report: market.marketReport,
						});
					}
				}
				
			}
		};
		this.save(subscriptions);
	}

	render() {
		const { market, classes } = this.props;
		const { selectedLeads, resetSelect, saving } = this.state;
		const subscribeAlertText = "Alerts and report pages are not available for Markets using manual listing numbers, date ranges or open-only fields.";
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Add Leads</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item >
					<Mui.Grid container direction="row" wrap="nowrap" spacing={2}>
						<Mui.Grid item xs={9} style={{backgroundColor: "#f2f2f2", borderRadius: 5}}>
							<FindSelect 
								items={this.props.leads.map((lead) => ({
										id: lead.id,
										value: lead.label,
										label: lead.label,
									})
								)}
								includedItems={this.props.market.marketReportSubscriptions?.map((value) => ({
										id: value.lead.id,
										value: value.lead.label,
										label: value.lead.label,
									})
								)}
								includedItemsLabel="Update"
								reset={resetSelect}
								onUpdate={(selected, resetSelect) => {
									this.setState({selectedLeads: selected, resetSelect: !!resetSelect});
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="column" wrap="nowrap">
								<Mui.Grid item>
									<Mui.Typography>Receive:</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container alignItems="center" wrap="nowrap">
										{market.listingReportSupported ? (
											<Mui.Checkbox
												checked={this.state.listingReport}
												onClick={() => this.setState({listingReport: !this.state.listingReport})}
											/>
										): 
											<Mui.Tooltip 
												title={subscribeAlertText}
											>
												<Icons.AlertCircle className={classes.alertCircle}/>
											</Mui.Tooltip>
										}
										<Mui.Typography>Listing Report</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container alignItems="center" wrap="nowrap">
										{market.openHomesReportSupported ? (
											<Mui.Checkbox
												checked={this.state.openHomeReport}
												onClick={() => this.setState({openHomeReport: !this.state.openHomeReport})}
											/>
										): 
											<Mui.Tooltip 
												title={subscribeAlertText}
											>
												<Icons.AlertCircle className={classes.alertCircle}/>
											</Mui.Tooltip>
										}
										<Mui.Typography>Open House Reports</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container alignItems="center" wrap="nowrap">
										{market.marketReportSupported ? (
											<Mui.Checkbox
												checked={this.state.marketReport}
												onClick={() => this.setState({marketReport: !this.state.marketReport})}
											/>
										): 
										<Mui.Tooltip 
											title={subscribeAlertText}
										>
											<Icons.AlertCircle className={classes.alertCircle}/>
										</Mui.Tooltip>
										}
										<Mui.Typography>Market Reports</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Tooltip title="Select one or more leads" disableHoverListener={selectedLeads.length > 0}>
										<SaveButton 
											onClick={() => {
												this.setState({submitted: true, saving: true});
												this.add();
											}} 
											loading={saving} 
											label={{
												primary: "Subscribe", 
												inProgress: `Saving... ${market.marketReportSubscriptions && market.marketReportSubscriptions.length > 100 ? "(This may take a minute)" : ""}`, 
												completed: "Saved" 
											}}
											disabled={selectedLeads.length === 0}
											snackBar
											duration={3000}
											onReset={() => this.setState({submitted: false})}
										/>
									</Mui.Tooltip>
									
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					{market.marketReportSubscriptions && market.marketReportSubscriptions.length > 0 && 
						<SubscriptionsTable saving={saving} market={market} onSave={(subscriptions) => this.save(subscriptions)} />
					}
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const MarketEditSubscriptions = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
)