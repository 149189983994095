import React from "react"
import * as Mui from "@material-ui/core";
import Fuse from "fuse.js";
import { Table } from "component/shared/table";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { MarketReportType } from "type/market-report-type";
import { styles } from "./styles";
import { Market } from "model/market";
import { SaveButton } from "component/shared/save-button";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { Lead } from "model/lead";

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
		market: Market,
		onSave: (subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[]) => void,
		saving: boolean,
}

type SubscriptionType = "listingReportSubscription" | "openHomeReportSubscription" | "marketReportSubscription";

interface TableRow {
	lead: Lead;
	listingReportSubscription?: MarketReportSubscription | NewMarketReportSubscription;
	openHomeReportSubscription?: MarketReportSubscription | NewMarketReportSubscription;
	marketReportSubscription?: MarketReportSubscription | NewMarketReportSubscription;
} 

interface State {
	data: { [id: number]: TableRow };
	term: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);


const mapStateToProps = (state: RootState) => {
	const loading = state.marketReportSubscriptions.loading;
	return {
		loading,
	}
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			data: {},
			term: "",
		};
	}

	public componentDidMount() {
		const { market } = this.props;
		const data = this.data(market.marketReportSubscriptions);
		if (data) {
			this.setState({data: data});
		}
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.market !== this.props.market) {
			const data = this.data(this.props.market.marketReportSubscriptions);
			if (data) {
				this.setState({data: data});
			}
		}
	}
	
	private data(subscriptions?: MarketReportSubscription[]) {
		if (subscriptions) {
			const tableRows = subscriptions.reduce<{[id: number]: TableRow}>((rows, subscription) => {
				const leadId = subscription.lead.id;
				if (!rows[leadId]) {
					const listingReportSubscription: NewMarketReportSubscription = {
						lead: subscription.lead,
						report: this.props.market.reports[0],
						inactive: true,
					};
					const openHomeReportSubscription: NewMarketReportSubscription = {
						lead: subscription.lead,
						report: this.props.market.reports[1],
						inactive: true,
					};
					const marketReportSubscription: NewMarketReportSubscription = {
						lead: subscription.lead,
						report: this.props.market.reports[2],
						inactive: true,
					};
					rows[leadId] = {
						lead: subscription.lead,
						listingReportSubscription,
						openHomeReportSubscription,
						marketReportSubscription,
					};
				}
				if (subscription.report.type === MarketReportType.LISTING) {
					rows[leadId].listingReportSubscription = subscription;
				}
				if (subscription.report.type === MarketReportType.OPEN_HOMES) {
					rows[leadId].openHomeReportSubscription = subscription;
				}
				if (subscription.report.type === MarketReportType.MARKET) {
					rows[leadId].marketReportSubscription = subscription;
				}
				if (rows[leadId].listingReportSubscription?.inactive && 
					rows[leadId].openHomeReportSubscription?.inactive && 
					rows[leadId].marketReportSubscription?.inactive) {
					delete rows[leadId];
				}
				return rows;
			}, {});
			return tableRows;
		}
	}

	private tableData = () => {
		return Object.values(this.state.data);
	}

	private tableDataFuse() {
		const options: Fuse.IFuseOptions<TableRow> = {
			shouldSort: true,
			threshold: 0.5,
			location: 0,
			distance: 100,
			minMatchCharLength: 1,
			keys: ["lead.email"],
		};

		return new Fuse(this.tableData(), options);
	}

	private save() {
		const { onSave } = this.props;
		const subscriptions = Object.values(this.state.data).flatMap(data => {
			return [
				data.listingReportSubscription, 
				data.marketReportSubscription, 
				data.openHomeReportSubscription
			]
		}).filter(Boolean) as MarketReportSubscription[];
		if (subscriptions && subscriptions.length > 0) {
			onSave(subscriptions);
		}
		
	}

	private handleOnChange(
		lead: Lead, 
		subscription: MarketReportSubscription | NewMarketReportSubscription, 
		reportSubscriptionType: SubscriptionType
	) {
		const result = {...this.state.data};
		const resultItem = result[lead.id];
		if (resultItem) {
			const item = resultItem[reportSubscriptionType];
			if (item) {
				item.inactive = !subscription.inactive;
			}
		}
		this.setState({data: result});
	}

	public render() {
		const { market, saving } = this.props;
		const { term } = this.state;
		let tableData = this.tableData();
		if (term) {
			tableData = this.tableDataFuse().search(term).map((result) => result.item);
		}
		return (	
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Grid container justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Grid item>
								<Mui.Typography variant="h3">{`Subscriber List: ${tableData.length}`}</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
							<Mui.Grid item xs={5}>
								<Mui.TextField 
									variant="outlined" 
									size="small" 
									fullWidth
									placeholder="Search Subscriptions"
									onChange={event => this.setState({term: event.target.value})}
								/>
							</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Table
						items={tableData}
						columns={[
							{
								id: "email",
								hidden: false,
								title: "Email Address",
								sort: (sub1, sub2) => {
									return sub2.lead.email.toLowerCase() < sub1.lead.email.toLowerCase() ? -1 : 1;
								},
								value: (data) => {
									return (
										<Mui.Typography>
											{data.lead.email}
										</Mui.Typography>
									);
								}
							},
							{
								id: "name",
								hidden: false,
								title: "Name",
								sort: (sub1, sub2) => {
									let name1;
									let name2;
									if (sub1.lead.name) {
										name1 = sub1.lead.name
									} else {
										name1 = sub1.lead.email
									}

									if (sub2.lead.name) {
										name2 = sub2.lead.name
									} else {
										name2 = sub2.lead.email
									}

									return name2.toLowerCase() < name1.toLowerCase() ? -1 : 1;
								},
								value: (data) => {
									return (
										<Mui.Typography>
											{data.lead.name}
										</Mui.Typography>
									);
								}
							},
							{
								id: "listing-report",
								title: "Listing Report",
								hidden: !market.listingReportSupported,
								sort: (sub1, sub2) => {
									const isActive1 = sub1.listingReportSubscription && !sub1.listingReportSubscription.inactive;
									const isActive2 = sub2.listingReportSubscription && !sub2.listingReportSubscription.inactive;
									// Sort based on active status (checked attribute)
									if (isActive1 && !isActive2) {
										return 1; // sub1 should come before sub2
									} else if (!isActive1 && isActive2) {
										return -1; // sub2 should come before sub1
									} else {
										return 0; // No change in order
									}
								},
								value: (data) => {
									const subscription = data.listingReportSubscription;
									return (
										<Mui.Checkbox 
											checked={subscription && !subscription.inactive}
											onChange={() => {
												subscription && this.handleOnChange(data.lead, subscription, "listingReportSubscription");
												
											}}
										/>
									)
								}
							},
							{
								id: "open-home-reports",
								title: "Open House Reports",
								hidden: !market.openHomesReportSupported,
								sort: (sub1, sub2) => {
									const isActive1 = sub1.openHomeReportSubscription && !sub1.openHomeReportSubscription.inactive;
									const isActive2 = sub2.openHomeReportSubscription && !sub2.openHomeReportSubscription.inactive;
									// Sort based on active status (checked attribute)
									if (isActive1 && !isActive2) {
										return 1; // sub1 should come before sub2
									} else if (!isActive1 && isActive2) {
										return -1; // sub2 should come before sub1
									} else {
										return 0; // No change in order
									}
								},
								value: (data) => {
									const subscription = data.openHomeReportSubscription;
									return (
										<Mui.Checkbox 
											checked={subscription && !subscription.inactive}
											onChange={() => {
												subscription && this.handleOnChange(data.lead, subscription, "openHomeReportSubscription");
												
											}}
										/>
									)
								}
							},
							{
								id: "market-reports",
								title: "Market Reports",
								hidden: !market.marketReportSupported,
								sort: (sub1, sub2) => {
									const isActive1 = sub1.marketReportSubscription && !sub1.marketReportSubscription.inactive;
									const isActive2 = sub2.marketReportSubscription && !sub2.marketReportSubscription.inactive;

									if (isActive1 && !isActive2) {
										return 1;
									} else if (!isActive1 && isActive2) {
										return -1;
									} else {
										return 0;
									}
								},
								value: (data) => {
									const subscription = data.marketReportSubscription;
									return (
										<Mui.Checkbox 
											checked={subscription && !subscription.inactive}
											onChange={() => {
												subscription && this.handleOnChange(data.lead, subscription, "marketReportSubscription");
												
											}}
										/>
									)
								}
							},
						]}
					/>
				</Mui.Grid>
				<Mui.Grid item sm={5}>
					<Mui.Grid item>
						<SaveButton 
							onClick={() => {
								this.save();
							}} 
							loading={saving} 
							label={{
								primary: "Save", 
								inProgress: `Saving... ${market.marketReportSubscriptions && market.marketReportSubscriptions.length > 100 ? "(This may take a minute)" : ""}`,  
								completed: "Saved" 
							}}
							snackBar
							duration={3000}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const SubscriptionsTable = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));