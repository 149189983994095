import { Moment } from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";

export interface MobileDialogDateState {
	date?: string;
	clickMobileLogin?: boolean;
};

const initialState: MobileDialogDateState = {}; 

export const mobileDialogDateSlice = createSlice({
	name: "mobileDialogDate",
	initialState,
	reducers: {
		resetMobileDialogDate: (state, action: PayloadAction<Moment>) => {
			const date = action.payload.toISOString()
			state.date = date;
		},
		setClickMobileLogin: (state, action: PayloadAction<boolean>) => {
			state.clickMobileLogin = action.payload;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
	},
});

export const { resetMobileDialogDate, setClickMobileLogin } = mobileDialogDateSlice.actions;