import React from "react";
import * as Mui from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Color } from '@material-ui/lab/Alert';
import * as Icons from "react-feather";
import { styles } from "./style";
import { TextMessageBrandingStatus } from "type/text-message-branding-status";
import { FeatherIcon } from "component/shared/feather-icon";

interface State {
	label: string;
}

interface OwnProps {
	onResendVerification: () => void;
}

const initialState: State = {
	label: "The account is under review"
}

interface Props extends 
	OwnProps,	
	Mui.WithStyles<typeof styles> {
	brandingStatus: TextMessageBrandingStatus;
}

class Component extends React.Component<Props, State> { 

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public render() {
		const { brandingStatus, onResendVerification } = this.props;
		let alertTittle = "";
		let alertDescription = "";
		let alertSeverity : Color = "success";
		switch(brandingStatus) {
			case TextMessageBrandingStatus.APPROVED:
				alertDescription = "Your text messaging number is verified";
				break
			case TextMessageBrandingStatus.FAILED:
				alertTittle = "Failed";
				alertDescription = "Please contact our support team for more information";
				alertSeverity = "error"
				break
			case TextMessageBrandingStatus.PENDING:
				alertTittle = "Pending";
				alertDescription = "Your text messaging number is under review";
				alertSeverity = "info"
				break
			case TextMessageBrandingStatus.UNVERIFIED:
				alertTittle = "Verification Needed";
				alertDescription = "You must verify your mobile phone number before texts can be sent";
				alertSeverity = "warning"
				break
		}
		return (
			<>
				{brandingStatus.approved ? 
					(
						<Mui.Grid container direction="row">
								<Mui.Grid item>
									<FeatherIcon fontSize="small">
										<Icons.CheckCircle color="green" />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Typography variant="caption">
										{alertDescription}
									</Mui.Typography>
								</Mui.Grid>
						</Mui.Grid>
					) : (
						<Alert variant="standard" severity={alertSeverity}
							action={(brandingStatus.unverified) &&
								<Mui.Button variant="contained" color="secondary" size="small" onClick={onResendVerification}>
									<Mui.Typography>
										Resend Verification Code
									</Mui.Typography>
								</Mui.Button>
							}
						>
							<Mui.Typography variant="h2">
								{alertTittle}
							</Mui.Typography>
							<Mui.Typography>
								{alertDescription}
							</Mui.Typography>
						</Alert>
				)}
			</>
		);
	}

}

export const BrandingStatus = Mui.withStyles(styles)(
	(Component)
);