import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUser, getAdminMode, getMobileDialogDate, getMobileDialogClickLogin } from "redux/selector";
import { resetMobileDialogDate, setClickMobileLogin } from "redux/slice/mobile-dialog-date";
import moment from "moment";
import * as Mui from "@material-ui/core";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		adminMode: getAdminMode(state),
		mobileDialogDate: getMobileDialogDate(state),
		mobileDialogClickLogin: getMobileDialogClickLogin(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	resetMobileDialogDate,
	setClickMobileLogin
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State {
	open?: boolean;
	loaded: boolean;
	waitTime: number;
}

const waitTime = 10;

class Component extends React.Component<Props, State> {

	private interval = setInterval(() => {
		if (this.state.loaded) {
			if (this.state.waitTime > 0) {
				this.setState({
					waitTime: this.state.waitTime - 1,
				})
			} else {
				clearInterval(this.interval);
			}
		}
	}, 1000);

	public constructor(props: Props) {
		super(props);
		this.state = {
			loaded: false,
			waitTime,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.user.boardIds.includes(251)) return;
		if (this.state.open === undefined && this.props.user.permissions.mobileApp && this.props.adminMode === false) {
			const hasMobileDialogDate = Boolean(this.props.mobileDialogDate);
			const isSkipExpired = hasMobileDialogDate &&
				moment().subtract(24, 'hours').isSameOrAfter(this.props.mobileDialogDate);

			const hasMobileLogin = Boolean(this.props.user.lastMobileLogin);
			const isMobileLoginExpired = hasMobileLogin &&
				moment().subtract(30, 'days').isSameOrAfter(this.props.user.lastMobileLogin);
				
			if ((hasMobileLogin && !isMobileLoginExpired) || (hasMobileDialogDate && !isSkipExpired)) return;
			if (isMobileLoginExpired || !hasMobileLogin || isSkipExpired || !hasMobileDialogDate) {
				this.setState({ open: true });
			}			
		}
	}

	private formatTime(seconds: number) { 
		let minutes = Math.floor(seconds / 60);
		let remainingSeconds = seconds % 60;
		let formattedMinutes = String(minutes).padStart(2, '0');
		let formattedSeconds = String(remainingSeconds).padStart(2, '0');
		return `${formattedMinutes}:${formattedSeconds}`;
	}

	private onClose() {
		this.setState({ open: false });
		this.props.resetMobileDialogDate(moment());
	}

    render() {
		const { open, waitTime } = this.state;
		if (!open) {
			return null;
		}
        return (
			<Mui.Dialog
				open={true}
				fullScreen
			>
				<Mui.DialogContent
					style={{
						display: "flex",
						padding: 0,
						backgroundColor: "#36747B",
					}}>
					<iframe
						style={{
							width: "100%",
						}}
						onLoad={() => this.setState({ loaded: true })}
						onError={() => this.onClose()}
						src="https://www.ihomefinder.com/landing/control-panel-app-splash-page/"
					></iframe>
				</Mui.DialogContent>
				<Mui.DialogActions
					style={{
						height: 0,
						padding: 0,
						justifyContent: "center",
					}}
				>
					<div
						style={{
							bottom: 50,
							position: "absolute",
						}}
					>
						{waitTime ? (
							<Mui.Typography
								style={{
									fontSize: "1.75rem",
									fontWeight: "bold",
									color: "#fff",
								}}
							>
								{this.formatTime(waitTime)}
							</Mui.Typography>
						) : (
							<>
								<Mui.Button
									variant="contained"
									color="primary"
									style={{
										textTransform: "revert",
										marginRight: 10,
										backgroundColor: "#fb7434",
										color: "#000",
										fontWeight: "bold",
									}}
									onClick={() => {
										window.location.reload();
									}}
								>
									I've logged into the app
								</Mui.Button>
								<Mui.Button
									variant="contained"
									style={{
										textTransform: "revert",
									}}
									onClick={() => this.onClose()}
								>
									Skip for now
								</Mui.Button>
							</>
						)}
					</div>
				</Mui.DialogActions>
			</Mui.Dialog>
		)
	}
}

export const MobileAppDialog = connect(mapStateToProps, mapDispatchToProps)(Component)



/*

header,
#footer,
footer {
    display: none !important;
}

#main {
    padding: 0 !important;
}

 */