import { User } from "model/user";
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { QRCodeSVG } from "qrcode.react";
import { get } from "../../../shared/credentials";
import CryptoJS from "crypto-js";

// Constants
const STORAGE_KEY = "ihf-deeplink";
const APP_PROTOCOL = "ihomefindermax://";
const EXPO_ENV_KEY = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_EXPO_TOKEN_AUTH_KEY || "");

/**
 * Encrypts credentials for deep linking
 * @param credentials - User credentials to encrypt
 * @returns Encrypted string
 */
const encryptCredentials = (credentials: any): string => {
	return CryptoJS.AES.encrypt(JSON.stringify(credentials), EXPO_ENV_KEY, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	}).toString();
};

/**
 * Creates a deep link URL with encrypted credentials
 * @param clientId - User's client ID
 * @param credentials - Encrypted credentials string
 * @returns Complete deep link URL
 */
const createDeepLinkUrl = (clientId: number, credentials: string, agentId?: number): string => {
	return `${APP_PROTOCOL}login?cid=${clientId}&credentials=${credentials}${agentId ? `&aid=${agentId}` : ""}`;
};

/**
 * Extracts client ID from a deep link URL
 * @param deepLink - Deep link URL string
 * @returns Client ID as a number or null if not found
 */
const extractClientId = (deepLink: string): number => {
	const httpUrl = deepLink.replace(APP_PROTOCOL, "http://");
	const url = new URL(httpUrl);
	const clientId = url.searchParams.get("cid");
	return clientId ? parseInt(clientId, 10) : 0;
};

/**
 * Extracts agent ID from a deep link URL
 * @param deepLink - Deep link URL string
 * @returns Agent ID as a number or null if not found
 */
const extractAgentId = (deepLink: string): number => {
	const httpUrl = deepLink.replace(APP_PROTOCOL, "http://");
	const url = new URL(httpUrl);
	const agentId = url.searchParams.get("aid");
	return agentId ? parseInt(agentId, 10) : 0;
};

interface Props {
	user: User;
	open: boolean;
	onClick: () => void;
}

const Component: React.FC<Props> = ({ user, open, onClick }) => {
	const { clientId, agentId } = user;
	const credentials = get();
	
	const getDeepLink = (): string => {
		const storedDeepLink = sessionStorage.getItem(STORAGE_KEY);
		// If no stored deep link exists, generate a new one
		if (!storedDeepLink) {
			const encrypted = encryptCredentials(credentials);
			const newDeepLink = createDeepLinkUrl(clientId, encrypted, agentId);
			sessionStorage.setItem(STORAGE_KEY, newDeepLink);
			return newDeepLink;
		}
		// Verify stored deep link matches current client and agent ID
		const storedClientId = extractClientId(storedDeepLink);
		const storedAgentId = extractAgentId(storedDeepLink);
		
		if (storedClientId !== clientId || storedAgentId !== agentId) {
			sessionStorage.removeItem(STORAGE_KEY);
			return getDeepLink(); // Recursively generate new deep link
		}
		return storedDeepLink;
	};

	const deepLink = getDeepLink();

	return (
		<Dialog open={open} onClose={onClick}>
			<DialogTitle>Mobile App Login</DialogTitle>
			<DialogContent style={{ textAlign: "center" }}>
				<DialogContentText style={{ width: "300px" }}>
					Please download the app first, then scan the QR code to log into the mobile
					app using your credentials.
				</DialogContentText>
				<QRCodeSVG value={deepLink} />
			</DialogContent>
		</Dialog>
	);
};

export const MobileAppLoginDialog = Component;
