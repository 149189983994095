import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { saveIntegration } from "redux/slice/integrations";
import { getIntegrationsNewestToOldest } from "redux/selector";
import { AddDialog } from "./add-dialog";
import { EditForm } from "./edit-form";
import { IntegrationType } from "type/integration-type";
import { urls } from "routes/urls";
import { exchangeNylasToken } from "api/api";
import { NylasTokenRequest } from "api/resource/nylas";

const mapStateToProps = (state: RootState) => {
	return {
		integrations: getIntegrationsNewestToOldest(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
}

interface State {
	addDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addDialogIsOpen: false,
		};
	}

	public async componentDidMount() {
		const url = new URL(window.location.href);
		const nylasUserCode = url.searchParams.get("code");
		const nylasUserState = url.searchParams.get("state");
		const { saveIntegration } = this.props;

		if (!nylasUserCode && !nylasUserState) return;

		const nylasRequest: NylasTokenRequest = {
			client_id: process.env.REACT_APP_NYLAS_CLIENT_ID_V3 || "",
			client_secret: process.env.REACT_APP_NYLAS_CLIENT_SECRET_V3 || "",
			code: nylasUserCode || "",
			redirect_uri: `${window.location.origin}${window.location.pathname}`,
			code_verifier: "nylas",
			grant_type: "authorization_code",
		};

		try {
			const response = await exchangeNylasToken(nylasRequest);

			// Only modify URL after successful token exchange
			window.history.replaceState(null, "", urls.settingsIntegrations);

			saveIntegration({
				integration: {
					integrationType: IntegrationType.EMAIL_CALENDAR,
					data: {
						nylasV3GrantId: response.grant_id,
					}
				}
			});
		} catch(error) {
			// eslint-disable-next-line
			console.error('Nylas token exchange failed:', error);
		}
	}

	public render() {
		const { user } = this.props;
		const { addDialogIsOpen } = this.state;
		const integrations = this.props.integrations.filter(integration => integration.integrationType.permitted(user));
		const title = "Integrations";
		return (
			<DashboardLayout
				permitted={user && user.permissions.version >= 9}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<AddDialog
					open={addDialogIsOpen}
					onClose={() => this.setState({ addDialogIsOpen: false })}
					user={user}
				/>
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({ addDialogIsOpen: true })}
						>
							<Mui.Typography>Add New</Mui.Typography>
						</Mui.Button>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container spacing={3}>
							{integrations.map((integration) => (
								<Mui.Grid key={integration.id} item xs={12} md={6} xl={4}>
									<EditForm integration={integration} user={user} />
								</Mui.Grid>
							))}
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const SettingsIntegrationsPage = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);