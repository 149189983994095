class Type {
	public static PRICE_HIGH_TO_LOW = new Type("pd", "Price (High to Low)");
	public static PRICE_LOW_TO_HIGH = new Type("lpa", "Price Low To High)");
	public static STATUS = new Type("st", "Status");
	public static CITY = new Type("cn", "City");
	public static LISTING_DATE = new Type("ds", "Listing Date");
	public static TYPE_PRICE_DESCENDING = new Type("lpd", "Type / Price Descending");
	public static LISTING_NUMBER = new Type("ln", "Listing Number");
	public static OPEN_HOME_DATE_ASC = new Type("ohd", "Open House Date Asc");

	private constructor(
		public readonly id: string,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.PRICE_HIGH_TO_LOW;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as ActiveResultsSortType };
