import { LeadActivitiesResource } from "api/resource/lead-activities";
import { listings } from "./listings";

export const leadActivities: LeadActivitiesResource[] = [
	{
		id: "1_0",
		leadId: 2,
		typeId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		message: "1 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. laboris nisi ut aliquip nostrud exercitation ullamco. ",
		listing: listings[1],
	},
	{
		id: "1_2",
		leadId: 2,
		typeId: 1,
		createdOn: "2019-06-02T14:46:41.723-08:00",
		message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
		listing: listings[0],
	},
	{
		id: "2_0",
		leadId: 1,
		typeId: 2,
		createdOn: "2019-06-02T14:46:41.723-08:00",
		message: "Hello hello! We will get back to you soon",
		preferableOn: "2019-03-03T14:30-08:00",
		alternativeOn: "2019-03-09T10:00-08:00",
		listing: listings[0],
	},
	{
		id: "3_0",
		leadId: 1,
		typeId: 3,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_1",
		leadId: 3,
		typeId: 1,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_2",
		leadId: 3,
		typeId: 2,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_3",
		leadId: 3,
		typeId: 3,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_4",
		leadId: 3,
		typeId: 4,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_5",
		leadId: 3,
		typeId: 5,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_6",
		leadId: 3,
		typeId: 6,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_7",
		leadId: 3,
		typeId: 7,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_8",
		leadId: 3,
		typeId: 8,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_9",
		leadId: 3,
		typeId: 9,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_10",
		leadId: 3,
		typeId: 10,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_11",
		leadId: 3,
		typeId: 11,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_12",
		leadId: 3,
		typeId: 12,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_13",
		leadId: 3,
		typeId: 13,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_14",
		leadId: 3,
		typeId: 14,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_15",
		leadId: 3,
		typeId: 15,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_16",
		leadId: 3,
		typeId: 16,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "4_17",
		leadId: 3,
		typeId: 17,
		createdOn: "2018-11-13T14:46:41.723-08:00",
	},
	{
		id: "5_0",
		leadId: 4,
		typeId: 13,
		message: "Test lead from Zillow Group\n\nYou can reach me at (415) 535-9517.",
		createdOn: "2018-10-25T15:53:40.577-07:00"
	},
	{
		id: "6_0",
		leadId: 2,
		typeId: 7,
		message: "I'm looking for an agent in my area and ran across your website. Please contact me when you can.",
		createdOn: "2018-10-23T10:51:46.147-07:00",
		criteria: [
			{
				name: "maxListPrice",
				label: "Max. Price",
				value: 500000,
			},
		],
	},
	{
		id: "7_0",
		leadId: 1,
		typeId: 16,
		createdOn: "2019-03-06T12:51:00.000-08:00",
		message: "Test",
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					1,
					2,
				],
			},
			{
				name: "propertyType",
				label: "Property Type",
				value: [
					"CND",
					"SFR"
				],
			},
			{
				name: "poolYn",
				label: "Pool",
				value: true,
			},
			{
				name: "basementYn",
				label: "Basement",
				value: true,
			},
			{
				name: "openHomesOnlyYn",
				label: "Open Houses",
				value: true,
			},
			{
				name: "viewYn",
				label: "View",
				value: true,
			},
			{
				name: "minListPrice",
				label: "Min. Price",
				value: 500000,
			},
			{
				name: "maxListPrice",
				label: "Max. Price",
				value: 800000,
			},
			{
				name: "bedrooms",
				label: "Bedrooms",
				value: 4,
			},
			{
				name: "bathCount",
				label: "Bathrooms",
				value: 2,
			},
			{
				name: "mlsarea",
				label: "MLS Area",
				area: true,
				value: [
					"Bethel Island/Byron/Knigh",
				],
			},
			{
				name: "neighborhood",
				label: "Neighborhood",
				area: true,
				value: [
					"CA CHATEAU",
					"CA PALACEONBEACH",
				],
			},
			{
				name: "miscString4",
				label: "View",
				value: "Mountains",
			},
			{
				name: "propertySubType",
				label: "Property Sub Type",
				value: [
					"Detached",
					"Townhouse",
				],
			},
		],
	},
	{
		id: "8_0",
		leadId: 2,
		typeId: 18,
		message: "Yes!",
		createdOn: "2022-02-08T10:51:46.147-07:00",
		textMessageActivity: {
			campaignId: 100,
			campaignMessageId: 1,
			dateSent: "2022-02-06T10:51:46.147-07:00",
		}
	},
	{
		id: "9_0",
		leadId: 2,
		typeId: 19,
		createdOn: "2022-05-13T16:52:46.147-05:00",
		callForwardingEventActivity: {
			calledFromNumber: "9086543388",
			callForwardingNumber: "9086543399"
		}
	},
	{
		id: "8_0",
		leadId: 2,
		typeId: 18,
		message: "Yes!",
		createdOn: "2022-02-08T10:51:46.147-07:00",
		textMessageActivity: {
			campaignId: 100,
			campaignMessageId: 1,
			dateSent: "2022-02-06T10:51:46.147-07:00",
		},
		listing: listings[1],
	}
];