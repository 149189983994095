import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration } from "redux/slice/integrations";
import { fetchIntegrationCalendars } from "redux/slice/integration-calendars";
import { Integration } from "model/integration";
import { User } from "model/user";
import { Select } from "component/shared/select";
import { getIntegrationCalendarsByIntegrationId } from "redux/selector";
import { Card } from "../card";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		integrationCalendars: getIntegrationCalendarsByIntegrationId(state, ownProps.integration.id),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
	fetchIntegrationCalendars,
}, dispatch); 

interface OwnProps {
	integration: Integration;
	user: User;
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{}

interface State {
	calendarId?: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			calendarId: props.integration.data.calendarId as string || props.integration.data.nylasV3CalendarId as string,
		};
	}

	public componentDidMount() {
		const { integration } = this.props
		if (!integration.data.nylasCalendarId || !integration.data.nylasV3CalendarId) {
			this.props.fetchIntegrationCalendars({ integrationId: this.props.integration.id});
		}
	}

	public render() {
		const { integration, saveIntegration, integrationCalendars } = this.props;
		const { calendarId } = this.state;
		const showSaveButton = !integration.data.nylasCalendarId && !integration.data.nylasV3CalendarId;

		return (
			<Card
				integration={integration}
				deletable={true}
				content={
					<Mui.Grid container direction="column" spacing={2}>
						{integration.errored ? (
							<Mui.Grid item>
								<MuiAlert severity="error">
									<Mui.Typography>There's an issue with your integration. Please delete it and re-add it again.</Mui.Typography>
								</MuiAlert>
							</Mui.Grid>
						) : integration.data.nylasCalendarId || integration.data.nylasV3CalendarId ? (
							<Mui.Grid item>
								<MuiAlert severity="success">
									<Mui.Typography>You have successfully integrated your account to send emails and sync To Do items.</Mui.Typography>
								</MuiAlert>
							</Mui.Grid>
						) : (
							<>
								<Mui.Grid item>
									<MuiAlert severity="success">
										<Mui.Typography>You have successfully integrated your account to send emails!</Mui.Typography>
									</MuiAlert>
								</Mui.Grid>
								<Mui.Grid item>
									<MuiAlert severity="info">
										<Mui.Typography>To sync To Do items, select a Calendar from your account below.</Mui.Typography>
									</MuiAlert>
								</Mui.Grid>
								<Mui.Grid item>
									<Select
										variant="outlined"
										margin="dense"
										fullWidth
										placeholder="Select a Calendar"
										value={calendarId}
										options={integrationCalendars}
										valueExtractor={calendar => calendar.id}
										labelExtractor={calendar => calendar.name}
										optionRenderer={calendar => (
											<Mui.ListItemText primary={calendar.name} />
										)}
										onChange={value => this.setState({ calendarId: value })}
									/>
								</Mui.Grid>
							</>
						)}
					</Mui.Grid>
				}
				actions={showSaveButton && (
					<Mui.Grid container spacing={2}>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => saveIntegration({ integration: {
									...integration,
									data: {
										...integration.data,
										nylasV3CalendarId: calendarId
									},
								}})}
							>
								Save
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				)}
			/>
		);
	}

}

export const EmailCalendarIntegration = connect(mapStateToProps, mapDispatchToProps)(Component);