import { UserPartial } from "model/user";
import { UserAuth } from "model/user-auth";
import { QuotaResource } from "./resource/quota";
import { Lead, NewLead } from "model/lead";
import { LeadResource } from "./resource/lead";
import { LeadImportResource } from "./resource/lead-import";
import { LeadNote, NewLeadNote } from "model/lead-note";
import { LeadNoteResource } from "./resource/lead-note";
import { LeadTag, NewLeadTag } from "model/lead-tag";
import { LeadTagResource } from "./resource/lead-tag";
import { LeadPhone, NewLeadPhone } from "model/lead-phone";
import { LeadPhoneResource } from "./resource/lead-phone";
import { NewLeadPipeline } from "model/lead-pipeline";
import { LeadPipelineResource } from "./resource/lead-pipeline";
import { LeadAnniversary, NewLeadAnniversary } from "model/lead-anniversary";
import { LeadAnniversaryResource } from "./resource/lead-anniversary";
import { SavedListing, NewSavedListing } from "model/saved-listing";
import { SavedListingResource } from "./resource/saved-listing";
import { SavedSearch, NewSavedSearch } from "model/saved-search";
import { SavedSearchResource } from "./resource/saved-search";
import { MarketReportSubscriptionResource } from "./resource/market-report-subscription";
import { Campaign, NewCampaign } from "model/campaign";
import { CampaignResource } from "./resource/campaign";
import { CampaignMessage, NewCampaignMessage } from "model/campaign-message";
import { CampaignMessageResource } from "./resource/campaign-message";
import { CampaignCampaignMessage, NewCampaignCampaignMessage } from "model/campaign-campaign-message";
import { CampaignCampaignMessageResource } from "./resource/campaign-campaign-message";
import { CampaignSubscription, NewCampaignSubscription } from "model/campaign-subscription";
import { CampaignSubscriptionResource } from "./resource/campaign-subscription";
import { TaskResource } from "./resource/task";
import { NewTask, Task } from "model/task";
import moment from "moment";
import { IntegrationPartial } from "model/integration";
import { IntegrationResource } from "./resource/integration";
import { CampaignRouteResource } from "./resource/campaign-route";
import { CampaignRoute, NewCampaignRoute } from "model/campaign-route";
import { CampaignStatus } from "type/campaign-status";
import { CampaignCampaignMessageSendEmailResource } from "./resource/campaign-campaign-message-send-email";
import { CampaignCampaignMessageSendEmail } from "model/campaign-campaign-message-send-email";
import { CampaignsSettingsResource } from "./resource/campaigns-settings";
import { CampaignsSettings } from "model/campaigns-settings";
import { Agent, NewAgent } from "model/agent";
import { AgentResource } from "./resource/agent";
import { AgentBoard, NewAgentBoard } from "model/agent-board";
import { AgentBoardResource } from "./resource/agent-board";
import { Office, NewOffice } from "model/office";
import { OfficeResource } from "./resource/office";
import { OfficeBoard, NewOfficeBoard } from "model/office-board";
import { OfficeBoardResource } from "./resource/office-board";
import { CampaignMessageType } from "type/campaign-message";
import { PagePartial } from "model/page";
import { PageResource } from "./resource/page";
import { MarketResource } from "./resource/market";
import { listingIdToParts } from "shared/listing-id";
import { user } from "./mock-data/user";
import { adminMode } from "./mock-data/admin-mode";
import { resellers } from "./mock-data/resellers";
import { clients } from "./mock-data/clients";
import { quota } from "./mock-data/quota";
import { leads } from "./mock-data/leads";
import { savedListings } from "./mock-data/saved-listings";
import { savedSearches } from "./mock-data/saved-searches";
import { savedSearchResultTotals } from "./mock-data/saved-search-result-totals";
import { emailSents } from "./mock-data/email-sents";
import { emailClicks } from "./mock-data/email-clicks";
import { leadPhones } from "./mock-data/lead-phones";
import { leadPipelines } from "./mock-data/lead-pipelines";
import { leadAnniversaries } from "./mock-data/lead-anniversaries";
import { leadNotes } from "./mock-data/lead-notes";
import { leadTags } from "./mock-data/lead-tags";
import { leadActivities } from "./mock-data/lead-activities";
import { leadImports } from "./mock-data/lead-imports";
import { leadActivityStats } from "./mock-data/lead-activity-stats";
import { leadLastActivities } from "./mock-data/lead-last-activities";
import { campaigns } from "./mock-data/campaigns";
import { geographicRoutes } from "./mock-data/geographic-routes";
import { postalCodes} from "./mock-data/postal-codes";
import { roundRobinRoutes } from "./mock-data/round-robin-routes";
import { campaignMessages } from "./mock-data/campaign-messages";
import { campaignCampaignMessages } from "./mock-data/campaign-campign-messages";
import { campaignSubscriptions } from "./mock-data/campaign-subscriptions";
import { campaignEmails } from "./mock-data/campaign-emails";
import { campaignStats } from "./mock-data/campaign-stats";
import { campaignSubscriptionStats } from "./mock-data/campaign-subscription-stats";
import { campaignCampaignMessageStats } from "./mock-data/campaign-campaign-message-stats";
import { campaignRoutes } from "./mock-data/campaign-routes";
import { campaignsSettings } from "./mock-data/campaign-settings";
import { markets } from "./mock-data/markets";
import { listings } from "./mock-data/listings";
import { marketListings } from "./mock-data/market-listings";
import { marketReports } from "./mock-data/market-reports";
import { MarketListingResource } from "./resource/market-listing";
import { marketReportSubscriptions } from "./mock-data/market-report-subscriptions";
import { tasks } from "./mock-data/tasks";
import { agents } from "./mock-data/agents";
import { boards } from "./mock-data/boards";
import { agentBoards } from "./mock-data/agent-boards";
import { offices } from "./mock-data/offices";
import { officeBoards } from "./mock-data/office-boards";
import { cities } from "./mock-data/cities";
import { neighborhoods } from "./mock-data/neighborhoods";
import { aggregateStats } from "./mock-data/aggregate-stats";
import { integrations } from "./mock-data/intergrations";
import { integrationCalendars } from "./mock-data/intergration-calenders";
import { siteLinks } from "./mock-data/site-links";
import { pages } from "./mock-data/pages";
import { languages } from "./mock-data/languages";
import { MarketReportResource } from "./resource/market-report";
import { NewRoundRobinRoute } from "model/round-robin-route";
import { RoundRobinRouteResource } from "./resource/round-robin-route";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import { GeographicRouteResource } from "./resource/geographic-route";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { Market, NewMarket } from "model/market";
import { MarketListing } from "model/market-listing";
import { MarketReport } from "model/market-report";
import { MarketSettingsResource } from "./resource/market-settings";
import { MarketSettings } from "model/market-settings";
import { marketSettings } from "./mock-data/market-settings";
import { NylasTokenRequest, NylasTokenResponse } from "./resource/nylas";

const resolve = <T>(data: T, delay = 500) => {
    return new Promise<T>((resolve, reject) => {
        setTimeout(() => resolve(data), delay);
    });
}

const generateId = () => {
    return parseInt(Math.random().toString().split(".")[1]);
}

const generateTimestamp = () => {
    return moment().toISOString();
}

export const getUser = () => {
    return resolve(user);
}

export const saveUser = (userPartial: UserPartial) => {
    if (userPartial.textMessageAreaCode && (userPartial.textMessageAreaCode === "999" || userPartial.textMessageAreaCode === "000")) {
        throw new Error("Area code failed");
    }
    if (userPartial.textMessageAreaCode) {
        user.textMessageAreaCode = userPartial.textMessageAreaCode;
    }
    if (userPartial.textMessageCallForwarding) {
        user.textMessageCallForwarding = userPartial.textMessageCallForwarding;
    }
    if (userPartial.textMessageOptIn) {
        user.textMessageOptIn = userPartial.textMessageOptIn;
    }
    if (userPartial.kestrelVersion) {
        user.kestrelVersion = userPartial.kestrelVersion.id;
    }
    if (userPartial.baseUrl) {
        user.baseUrl = userPartial.baseUrl;
    }
    if (userPartial.websitePlatform) {
        user.websitePlatform = typeof userPartial.websitePlatform === "string" ? userPartial.websitePlatform : userPartial.websitePlatform.id;
    }
    if (userPartial.colorScheme) {
        user.colorScheme = userPartial.colorScheme;
    }
    return resolve(user, 2000);
}

export const saveUserAuth = (userAuth: UserAuth) => {
    return resolve(userAuth);
}

export const getAdminMode = () => {
    return resolve(adminMode);
}

export const getResellerById = (id: number) => {
    const reseller = resellers.find((reseller) => reseller.id === id);
    if (!reseller) {
        throw new Error();
    }
    return resolve(reseller);
}

export const getClient = (id: number) => {
    const client = clients.find((client) => client.id === id);
    if (!client) {
        throw new Error();
    }
    return resolve(client);
}

export const getQuota = () => {
    return resolve<QuotaResource>(quota);
}

export const getLeads = () => {
    return resolve<LeadResource[]>(leads);
}

export const createLead = (lead: NewLead) => {
	const resource: LeadResource = {
		id: generateId(),
		name: lead.name,
		phone: lead.phone,
		email: lead.email,
		email2: lead.email2,
		address: lead.address,
		city: lead.city,
		state: lead.state,
		zip: lead.zip,
		note: lead.note,
		status: lead.status && lead.status.id,
		agentId: lead.agent ? lead.agent.id : undefined,
		createdOn: generateTimestamp(),
	}
	leads.push(resource);
	return resolve(resource);
}

export const updateLead = (lead: Lead) => {
	const i = leads.findIndex((value) => value.id === lead.id);
	const resource = {
		...leads[i],
		name: lead.name,
		phone: lead.phone,
		email: lead.email,
		email2: lead.email2,
		address: lead.address,
		city: lead.city,
		state: lead.state,
		zip: lead.zip,
		note: lead.note,
		status: lead.status && lead.status.id,
		agentId: lead.agent ? lead.agent.id : undefined,
	}
	leads[i] = resource;
	return resolve(resource);
}

export const getSavedListings = (leadId: number) => {
    return resolve(savedListings.filter((savedListing) => savedListing.leadId === leadId));
}

export const createSavedListing = (savedListing: NewSavedListing) => {
	const resource: SavedListingResource = {
		id: generateId(),
		leadId: savedListing.lead.id,
		createdOn: generateTimestamp(),
		rating: savedListing.rating,
		comments: savedListing.comments,
		deleted: savedListing.deleted ? savedListing.deleted : false,
		listing: {
			//@ts-ignore
			id: savedListing.listing ? savedListing.listing.id : undefined,
			listingNumber: "1001686857_2",
			address: "1213 Alvorado Ave\\n Davis, CA 94109",
			bedrooms: 3,
			fullBathrooms: 2,
			price: 700000,
			photoUrl: "https://img.zumpercdn.com/191663977/1280x960?auto=format&w=392&h=360",
			detailUrl: "https://example.com",
		},
	}
	return resolve<SavedListingResource>(resource);
}

export const updateSavedListing = (savedListing: SavedListing) => {
	const i = savedListings.findIndex((value) => value.id === savedListing.id);
	const resource = {
		...savedListings[i],
		comments: savedListing.comments,
		rating: savedListing.rating,
		deleted: savedListing.deleted ? savedListing.deleted : false,
	};
	savedListings[i] = resource;
	return resolve(resource);
}

export const getSavedSearches = () => {
    return resolve(savedSearches);
}

export const createSavedSearch = (savedSearch: NewSavedSearch) => {
	const resource: SavedSearchResource = {
		id: generateId(),
		leadId: savedSearch.leadId,
		comments: savedSearch.comments,
		recieveEmails: savedSearch.recieveEmails,
		inactive: savedSearch.inactive,
		criteria: savedSearch.criteria,
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		modifiedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	savedSearches.push(resource);
	return resolve(resource);
}

export const updateSavedSearch = (savedSearch: SavedSearch) => {
	const i = savedSearches.findIndex((value) => value.id === savedSearch.id);
	const resource = {
		...savedSearches[i],
		comments: savedSearch.comments,
		recieveEmails: savedSearch.recieveEmails,
		inactive: savedSearch.inactive,
		criteria: savedSearch.criteria,
	};
	savedSearches[i] = resource;
	return resolve(resource);
} 

export const getSavedSearchResultTotal = (savedSearchId: number) => {
    return resolve(savedSearchResultTotals.find(savedSearchResultTotal => savedSearchResultTotal.id === savedSearchId));
}

export const getEmailSents = (leadId: number) => {
    return resolve(emailSents.filter((emailSent) => emailSent.leadId === leadId));
}

export const getEmailClicks = (leadId: number) => {
    return resolve(emailClicks.filter((emailClick) => emailClick.leadId === leadId));
}

export const getLeadPhones = () => {
    return resolve(leadPhones);
}

export const createLeadPhone = (leadPhone: NewLeadPhone) => {
	const resource: LeadPhoneResource = {
		id: generateId(),
		leadId: leadPhone.leadId,
		typeId: leadPhone.type && leadPhone.type.id,
		value: leadPhone.value,
		deleted: leadPhone.deleted,
		createdOn: moment().utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: moment().utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	}
	leadPhones.push(resource);
	return resolve(resource);
}

export const updateLeadPhone = (leadPhone: LeadPhone) => {
	const i = leadPhones.findIndex((value) => value.id === leadPhone.id);
	const resource = {
		...leadPhones[i],
		leadId: leadPhone.leadId,
		typeId: leadPhone.type && leadPhone.type.id,
		value: leadPhone.value,
		deleted: leadPhone.deleted,
		updatedOn: moment().utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	}
	leadPhones[i] = resource;
	return resolve(resource);
}

export const getLeadPipelines = () => {
    return resolve(leadPipelines);
}

export const createLeadPipeline = (leadPipeline: NewLeadPipeline) => {
    let resource: LeadPipelineResource;
        resource = {
            id: generateId(),
            leadId: leadPipeline.leadId,
            typeId: leadPipeline.type && leadPipeline.type.id,
            createdOn: generateTimestamp(),
        }
        leadPipelines.push(resource);
    return resolve<LeadPipelineResource>(resource);
}

export const getLeadAnniversaries = () => {
    return resolve(leadAnniversaries);
}

export const createLeadAnniversary = (leadAnniversary: NewLeadAnniversary) => {
	const resource: LeadAnniversaryResource = {
		id: generateId(),
		leadId: leadAnniversary.lead && leadAnniversary.lead.id,
		typeId: leadAnniversary.type && leadAnniversary.type.id,
		startDate: leadAnniversary.startDate.toString(),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		deleted: false,
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	leadAnniversaries.push(resource);
	return resolve<LeadAnniversaryResource>(resource);
};

export const updateLeadAnniversary = (leadAnniversary: LeadAnniversary) => {
	const index = leadAnniversaries.findIndex((value) => value.id === leadAnniversary.id);
	const resource = {
		...leadAnniversaries[index],
		leadId: leadAnniversary.lead && leadAnniversary.lead.id,
		typeId: leadAnniversary.type && leadAnniversary.type.id,
		startDate: leadAnniversary.startDate.toString(),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		deleted: leadAnniversary.deleted,
	};
	leadAnniversaries[index] = resource;
	return resolve<LeadAnniversaryResource>(resource);
};

export const getLeadNotes = () => {
    return resolve(leadNotes);
}

export const createLeadNote = (leadNote: NewLeadNote) => {
	const text = (leadNote.text) ? leadNote.text : "";
	const resource: LeadNoteResource = {
		id: generateId(),
		leadId: generateId(),
		typeId: leadNote.type && leadNote.type.id,
		text,
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		deleted: false,
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	leadNotes.push(resource);
	return resolve<LeadNoteResource>(resource);
};

export const updateLeadNote = (leadNote: LeadNote) => {
	const i = leadNotes.findIndex((value) => value.id === leadNote.id);
	const text = (leadNote.text) ? leadNote.text : "";
    let resource: LeadNoteResource = {
		...leadNotes[i],
		leadId: leadNote.leadId,
		typeId: leadNote.type && leadNote.type.id,
		text,
		deleted: leadNote.deleted,
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
    leadNotes[i] = resource;
	return resolve<LeadNoteResource>(resource);
};

export const getLeadTags = () => {
    return resolve(leadTags);
}

export const createLeadTag = (leadTag: NewLeadTag) => {
    const resource: LeadTagResource = {
        id: generateId(),
        leadId: leadTag.leadId,
        text: leadTag.text,
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    };
    leadTags.push(resource);
    return resolve<LeadTagResource>(resource);
}

export const deleteLeadTag = (leadTag: LeadTag) => {
    const i = leadTags.findIndex(value => value.id === leadTag.id);
    const resource = leadTags[i];
    leadTags.splice(i, 1);
    return resolve(resource);
}

export const getLeadActivities = (leadId?: number) => {
    if (leadId) {
        return resolve(leadActivities.filter((leadActivity) => leadActivity.leadId === leadId));
    } else {
        return resolve(leadActivities);
    }
}

export const saveLeadImport = (leadImportResources: LeadImportResource[]) => {
    leadImportResources.forEach((leadImportResource) => {
        let leadResource: LeadResource; 
        const i = leads.findIndex((value) => value.email === leadImportResource.email);
        if (leads[i] && leads[i].status === "deleted") {
            leadResource = {
                ...leads[i],
                name: leadImportResource.name,
                phone: leadImportResource.phone,
                email: leadImportResource.email,
                address: leadImportResource.address,
                city: leadImportResource.city,
                state: leadImportResource.state,
                zip: leadImportResource.zip,
                status: "active",
            }
            if (leadImportResource.note === "error") {
                leadImportResource.message = "import_failed";
            }
            if (leadImportResource.note === "action") {
                leadImportResource.message = "action_failed";
            }
            if (!leadImportResource.message) {
                leads[i] = leadResource;
            }
        } else if (leads[i]) {
            leadImportResource.message = "duplicate_lead";
        } else {
            leadResource = {
                id: generateId(),
                name: leadImportResource.name,
                phone: leadImportResource.phone,
                email: leadImportResource.email,
                address: leadImportResource.address,
                city: leadImportResource.city,
                state: leadImportResource.state,
                zip: leadImportResource.zip,
                status: "active",
            };
            if (leadImportResource.note === "error") {
                leadImportResource.message = "import_failed";
            }
            if (leadImportResource.note === "action") {
                leadImportResource.message = "action_failed";
            }
            if (!leadImportResource.message) {
                leads.push(leadResource);
            }
        }
    });
    leadImportResources.forEach((leadImportResource) => leadImports.push(leadImportResource));
    return resolve<LeadImportResource[]>(leadImportResources);
}

export const getLeadActivityStats = () => {
    return resolve(leadActivityStats);
}

export const getLeadLastActivities = () => {
    return resolve(leadLastActivities);
}

export const getCampaigns = () => {
    return resolve(campaigns);
}

export const createCampaign = (campaign: NewCampaign) => {
	const resource: CampaignResource = {
		id: generateId(),
		name: campaign.name,
		emailMarketingTypeId: campaign.emailMarketingType.id,
		dateCreated: generateTimestamp(),
		status: CampaignStatus.ACTIVE.id,
		inactiveYn: campaign.inactiveYn,
	};
	campaigns.push(resource);
	return resolve<CampaignResource>(resource);
};

export const updateCampaign = (campaign: Campaign) => {
	const resource: CampaignResource = {
		id: campaign.id,
		name: campaign.name,
		emailMarketingTypeId: campaign.emailMarketingType.id,
		dateCreated: campaign.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		status: campaign.status?.id,
		inactiveYn: campaign.inactiveYn,
	};
	const i = campaigns.findIndex((value) => value.id === campaign.id);
	campaigns[i] = resource;
	return resolve<CampaignResource>(resource);
};

export const getGeographicRoutes = () => {
	return resolve(geographicRoutes);
}

export const createGeographicRoute = (geographicRoute: NewGeographicRoute) => {
	const resource: GeographicRouteResource = {
		id: generateId(),
		agentId: geographicRoute.agent.id,
		postalCode: geographicRoute.postalCode,
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	}
	geographicRoutes.push(resource);
	return resolve<GeographicRouteResource>(resource);
};


export const deleteGeographicRoute = (geographicRoute: GeographicRoute) => {
		const i = geographicRoutes.findIndex(value => value.id === geographicRoute.id);
		const resource = geographicRoutes[i];
		geographicRoutes.splice(i, 1);
		return resolve(resource);
}

export const getRoundRobinRoutes = () => {
	return resolve(roundRobinRoutes);
}

export const updateRoundRobinRoutes = (_roundRobinRoutes: NewRoundRobinRoute[]) => {
	roundRobinRoutes.length = 0;
	for (let i = 0; i < _roundRobinRoutes.length; i++) {
		const resource: RoundRobinRouteResource = {
			id: generateId(),
			agentId: _roundRobinRoutes[i].agent.id,
			createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		}
		roundRobinRoutes.push(resource);
	}
	return resolve<RoundRobinRouteResource[]>(roundRobinRoutes);
}

export const getPostalCodes = () => {
	return resolve(postalCodes);
}


export const getCampaignMessages = () => {
    return resolve(campaignMessages);
}

export const resendTextingOtpVerification = () => {
	return resolve(true);
}

export const createCampaignMessage = (campaignMessage: NewCampaignMessage) => {
	const resource: CampaignMessageResource = {
		id: generateId(),
		name: campaignMessage.name,
		subject: campaignMessage.subject,
		htmlContent: campaignMessage.htmlContent,
		jsonContent: campaignMessage.jsonContent,
		messageType: campaignMessage.messageType ? campaignMessage.messageType.id : CampaignMessageType.TEXT.id,
		timeToSendNumber: campaignMessage.timeToSendNumber,
		timeToSendUnit: campaignMessage.timeToSendUnit && campaignMessage.timeToSendUnit.id,
		status: campaignMessage.status && campaignMessage.status.id,
		ownedByReseller: campaignMessage.ownedByReseller,
		isInactiveYn: campaignMessage.isInactiveYn,
		attachments: campaignMessage.attachments,
		dateCreated: generateTimestamp(),
	};
	campaignMessages.push(resource);
	return resolve<CampaignMessageResource>(resource);
};

export const updateCampaignMessage = (campaignMessage: CampaignMessage) => {
	const resource: CampaignMessageResource = {
		id: campaignMessage.id,
		name: campaignMessage.name,
		subject: campaignMessage.subject,
		htmlContent: campaignMessage.htmlContent,
		jsonContent: campaignMessage.jsonContent,
		messageType: campaignMessage.messageType ? campaignMessage.messageType.id : CampaignMessageType.TEXT.id,
		timeToSendNumber: campaignMessage.timeToSendNumber,
		timeToSendUnit: campaignMessage.timeToSendUnit && campaignMessage.timeToSendUnit.id,
		status: campaignMessage.status && campaignMessage.status.id,
		ownedByReseller: campaignMessage.ownedByReseller,
		isInactiveYn: campaignMessage.isInactiveYn,
		attachments: campaignMessage.attachments,
		dateCreated: campaignMessage.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	const i = campaignMessages.findIndex((value) => value.id === campaignMessage.id);
	campaignMessages[i] = resource;
	return resolve<CampaignMessageResource>(resource);
};

export const deleteCampaignMessage = (campaignMessage: CampaignMessage) => {
    const i = campaignMessages.findIndex(value => value.id === campaignMessage.id);
    const resource = campaignMessages[i];
    campaignMessages.splice(i, 1);
    return resolve(resource);
}

export const getCampaignCampaignMessages = () => {
    return resolve(campaignCampaignMessages);
}

export const createCampaignCampaignMessage = (campaignCampaignMessage: NewCampaignCampaignMessage) => {
	const resource: CampaignCampaignMessageResource = {
		id: generateId(),
		campaignId: campaignCampaignMessage.campaignId,
		campaignMessageId: campaignCampaignMessage.campaignMessageId,
		messageOrder: campaignCampaignMessage.messageOrder,
		dateCreated: generateTimestamp(),
	};
	campaignCampaignMessages.push(resource);
	return resolve<CampaignCampaignMessageResource>(resource);
};

export const updateCampaignCampaignMessage = (campaignCampaignMessage: CampaignCampaignMessage) => {
	const resource: CampaignCampaignMessageResource = {
		id: campaignCampaignMessage.id,
		campaignId: campaignCampaignMessage.campaignId,
		campaignMessageId: campaignCampaignMessage.campaignMessageId,
		messageOrder: campaignCampaignMessage.messageOrder,
		dateCreated: campaignCampaignMessage.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	const i = campaignCampaignMessages.findIndex((value) => value.id === campaignCampaignMessage.id);
	campaignCampaignMessages[i] = resource;
	return resolve<CampaignCampaignMessageResource>(resource);
};

export const updateCampaignCampaignMessages = (updatedCampaignCampaignMessages: CampaignCampaignMessage[]) => {
    updatedCampaignCampaignMessages.forEach((campaignCampaignMessage) => {
        let resource: CampaignCampaignMessageResource;
        resource = {
            id: campaignCampaignMessage.id,
            campaignId: campaignCampaignMessage.campaignId,
            campaignMessageId: campaignCampaignMessage.campaignMessageId,
            messageOrder: campaignCampaignMessage.messageOrder,
			dateCreated: campaignCampaignMessage.dateCreated.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        const i = campaignCampaignMessages.findIndex((value) => value.id === campaignCampaignMessage.id);
        campaignCampaignMessages[i] = resource;
    });
    return resolve(campaignCampaignMessages);
}

export const sendCampaignCampagaignMessageEmailTest = (campaignCampaignMessageSendEmail: CampaignCampaignMessageSendEmail) => {
    const resource: CampaignCampaignMessageSendEmailResource = {
        campaignCampaignMessageId: campaignCampaignMessageSendEmail.campaignCampaignMessage.id,
        leadId: campaignCampaignMessageSendEmail.lead.id,
    }
    return resolve<CampaignCampaignMessageSendEmailResource>(resource);
}

export const getCampaignSubscriptions = () => {
    return resolve(campaignSubscriptions);
}

export const createCampaignSubscription = (subscription: NewCampaignSubscription) => {
	const resource: CampaignSubscriptionResource = {
		id: generateId(),
		campaignId: subscription.campaign && subscription.campaign.id,
		leadId: subscription.lead && subscription.lead.id,
		status: subscription.status && subscription.status.id,
		createdOn: generateTimestamp(),
	};
	campaignSubscriptions.push(resource);
	return resolve<CampaignSubscriptionResource>(resource);
};

export const updateCampaignSubscription = (subscription: CampaignSubscription) => {
	const resource: CampaignSubscriptionResource = {
		id: subscription.id,
		campaignId: subscription.campaign && subscription.campaign.id,
		leadId: subscription.lead && subscription.lead.id,
		status: subscription.status && subscription.status.id,
		createdOn: subscription.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	const i = campaignSubscriptions.findIndex((value) => value.id === subscription.id);
	campaignSubscriptions[i] = resource;
	return resolve<CampaignSubscriptionResource>(resource);
};

export const getCampaignEmails = (leadId?: number, campaignId?: number, blast?: boolean) => {
    if (leadId || campaignId) {
        return resolve(campaignEmails.filter((campaignEmail) => campaignEmail.leadId === leadId || campaignEmail.campaignId === campaignId));
    }
    // if request doesn't include leadId or campaignId
    // then assume request wants all campaginEmails
    // aka blast is true
    return resolve(campaignEmails);
}

export const getCampaignStats = () => {
    return resolve(campaignStats);
}

export const getCampaignSubscriptionStats = () => {
    return resolve(campaignSubscriptionStats);
}

export const getCampaignCampaignMessageStats = () => {
    return resolve(campaignCampaignMessageStats);
}

export const getCampaignRoutes = () => {
    return resolve(campaignRoutes);
}

export const createCampaignRoute = (campaignRoute: NewCampaignRoute) => {
	const resource: CampaignRouteResource = {
		id: generateId(),
		campaignId: campaignRoute.campaign.id,
		leadSource: campaignRoute.leadSource,
	}
	campaignRoutes.push(resource);
	return resolve<CampaignRouteResource>(resource);
};

export const updateCampaignRoute = (campaignRoute: CampaignRoute) => {
	const resource: CampaignRouteResource = {
		id: campaignRoute.id,
		campaignId: campaignRoute.campaign.id,
		leadSource: campaignRoute.leadSource,
	}
	const i = campaignRoutes.findIndex((value) => value.id === campaignRoute.id);
	campaignRoutes[i] = resource;
	return resolve<CampaignRouteResource>(resource);
};

export const deleteCampaignRoute = (campaignRoute: CampaignRoute) => {
    const i = campaignRoutes.findIndex(value => value.id === campaignRoute.id);
    const resource = campaignRoutes[i];
    campaignRoutes.splice(i, 1);
    return resolve(resource);
}

export const getCampaignsSettings = () => {
    return resolve(campaignsSettings);
}

export const updateCampaignsSettings = (updatedCampaignsSettings: CampaignsSettings) => {
    const resource: CampaignsSettingsResource = {
        dynamicContentAddress: updatedCampaignsSettings.dynamicContentAddress,
        leadId: updatedCampaignsSettings.lead && updatedCampaignsSettings.lead.id
    }
    campaignsSettings.dynamicContentAddress = resource.dynamicContentAddress;
    if (resource.leadId) {
        campaignsSettings.leadId = resource.leadId;
    } else if (campaignsSettings.leadId) {
        campaignsSettings.leadId = undefined;
    }
    return resolve<CampaignsSettingsResource>(resource)
}

export const getMarkets = () => {
    return resolve(markets);
}

export const createMarket = (market: NewMarket) => {
    const marketResource: MarketResource = {
        ...market,
		inactive: market.inactive ? market.inactive : false,
        id: generateId(),
    }
    markets.push(marketResource);
    return resolve<MarketResource>(marketResource)
}

export const updateMarket = (market: Market) => {
	const resource: MarketResource = {
		id: market.id,
		name: market.name,
		inactive: market.inactive ? market.inactive : false,
	}
    const i = markets.findIndex((value) => value.id === market.id);
    markets[i] = resource;
    if (market.criteria) {
        const idsCriterion = market.criteria.find((criterion) => criterion.name === "ids");
        if (idsCriterion) {
            const values = idsCriterion.value as string[];
            values.forEach((value) => {
                const listingIndex = listings.findIndex((listing) => listing.id === value)
                const included = marketListings.some((marketListing) => marketListing.value === value);
                const parts = listingIdToParts(value);
                if (!included && parts) {
                    marketListings.push({
                        id: generateId(),
                        marketId: market.id,
                        value: parts.listingNumber ? parts.listingNumber : value,
						boardId: parts.boardId,
                        listing: listingIndex >= 0 ? listings[listingIndex] : undefined,
                    })
                }
            });
        }
    }
    return resolve<MarketResource>(resource)
}

export const getMarketSettings = () => {
    return resolve(marketSettings);
}

export const updateMarketSettings = (updatedMarketSettings: MarketSettings) => {
    const resource: MarketSettingsResource = {
        listingCarouselColumns: updatedMarketSettings.listingCarouselColumns,
		communitiesLayout: updatedMarketSettings.communitiesLayout
    }
    return resolve<MarketSettingsResource>(resource)
}

export const getMarketReports = () => {
    return resolve(marketReports);
}

export const updateMarketReport = (marketReport: MarketReport, marketId: number) => {
	const resource: MarketReportResource = {
		id: marketReport.id,
		typeId: marketReport.type.id,
		marketId: marketId,
		customUrl: marketReport.customUrl,
		inactive: marketReport.inactive, 
	};
    const i = marketReports.findIndex((value) => value.id === marketReport.id);
    marketReports[i] = resource;
    return resolve<MarketReportResource>(resource)
}

export const getMarketListings = () => {
    /**
     * simulate returning associated Listing object
     */
    const resources = marketListings.map((marketListing) => {
        const listingIndex = listings.findIndex((listing) => listing.id === marketListing.value)
        return {
            ...marketListing,
            listing: listings[listingIndex],
        }
    })
    return resolve(resources);
}

export const updateMarketListing = (marketListing: MarketListing) => {
	const resource: MarketListingResource = {
		id: marketListing.id,
		marketId: marketListing.marketId,
		boardId: marketListing.boardId,
		value: marketListing.value
	}
    const i = marketListings.findIndex((value) => value.id === resource.id);
    marketListings[i] = resource;
        const included = marketListings.some((marketListing) => marketListing.id === resource.id);
        if (!included) {
            marketListings.push({
                id: resource.id,
                marketId: resource.marketId,
                value: resource.value,
				boardId: resource.boardId,
                listing: resource.listing,
            })
        }
    return resolve<MarketListingResource>(resource)
}

export const updateMarketListings = (marketListings: MarketListing[]) => {
	const updatedResources = marketListings.map((resource) => {
	    const i = marketListings.findIndex((value) => value.id === resource.id);
	    marketListings[i] = resource;
	    const listingIndex = listings.findIndex((listing) => listing.id === resource.value)
	    const marketListingResource: MarketListingResource = {
	        id: resource.id,
	        marketId: resource.marketId,
	        value: resource.value,
			boardId: resource.boardId,
	        listing: listings[listingIndex],
	    }
	    return marketListingResource
	});
    return resolve<MarketListingResource[]>(updatedResources)
}

export const deleteMarketListing = (marketListing: MarketListing) => {
    const i = marketListings.findIndex(value => value.id === marketListing.id);
    const resource = marketListings[i];
    marketListings.splice(i, 1);
    return resolve(resource);
}

export const getMarketReportSubscriptions = () => {
    return resolve(marketReportSubscriptions);
}

//MarketSubscriptions

export const createMarketReportSubscription = (resource: NewMarketReportSubscription) => {
    const newResource: MarketReportSubscriptionResource = {
        id: generateId(),
        marketReportId: resource.report.id,
        leadId: resource.lead.id,
        inactive: resource.inactive,
    }
    marketReportSubscriptions.push(newResource);
    return resolve<MarketReportSubscriptionResource>(newResource);
}

export const updateMarketReportSubscription = (resource: MarketReportSubscription) => {
    const updateResource: MarketReportSubscriptionResource = {
        id: resource.id,
        marketReportId: resource.report.id,
        leadId: resource.lead.id,
        inactive: resource.inactive,
    };
    const i = marketReportSubscriptions.findIndex((value) => value.id === updateResource.id);
    marketReportSubscriptions[i] = updateResource;
    return resolve<MarketReportSubscriptionResource>(updateResource);
}

export const updateMarketSubscriptions = (marketId: number, subscriptions: (NewMarketReportSubscription | MarketReportSubscription)[]) => {
    subscriptions.forEach((subscription) => {
        if ("id" in subscription) {
			const resource: MarketReportSubscriptionResource = {
				id: subscription.id,
				marketReportId: subscription.report.id,
				leadId: subscription.lead.id,
				inactive: subscription.inactive,
			};
            const i = marketReportSubscriptions.findIndex((value) => value.id === resource.id);
            marketReportSubscriptions[i] = resource;
        } else {
			const resource: MarketReportSubscriptionResource = {
				id: generateId(),
				marketReportId: subscription.report.id,
				leadId: subscription.lead.id,
				inactive: subscription.inactive,
			}
            marketReportSubscriptions.push(resource);
        }
    });
    return resolve<MarketReportSubscriptionResource[]>(marketReportSubscriptions);
}

export const getTasks = () => {
    return resolve(tasks);
}
export const createTask = (task: NewTask) => {
	const resource = {
		id: generateId(),
		leadId: task.leadId,
		agentId: task.agentId,
		creatorId: task.creatorId,
		title: task.title,
		description: task.description,
		taskTypeId: task.type && task.type.id,
		taskStatusTypeId: task.status && task.status.id,
		priority: task.priority,
		startOn: task.startOn && task.startOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		endOn: task.endOn && task.endOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		createdOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	tasks.push(resource);
	return resolve<TaskResource>(resource);
};

export const updateTask = (task: Task) => {
	const resource: TaskResource = {
		id: task.id,
		leadId: task.leadId,
		agentId: task.agentId,
		creatorId: task.creatorId,
		title: task.title,
		description: task.description,
		taskTypeId: task.type && task.type.id,
		taskStatusTypeId: task.status && task.status.id,
		priority: task.priority,
		startOn: task.startOn && task.startOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		endOn: task.endOn && task.endOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		updatedOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
		createdOn: task.createdOn.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
	};
	const i = tasks.findIndex((value) => value.id === task.id);
	tasks[i] = resource;
	return resolve<TaskResource>(resource);
};

export const getAgents = () => {
    return resolve(agents);
}

export const createAgent = (agent: NewAgent) => {
	const resource: AgentResource = {
		id: generateId(),
		firstName: agent.firstName,
		lastName: agent.lastName,
		email: agent.email,
		officeId: agent.office && agent.office.id,
		agentPageUrl: agent.agentPageUrl,
		lastLoginDate: generateTimestamp(),
		designation: agent.designation,
		username: agent.username,
		password: agent.password,
		homePhone: agent.homePhone,
		officePhone: agent.officePhone,
		homeFax: agent.homeFax,
		officeFax: agent.officeFax,
		cellPhone: agent.cellPhone,
		staffTypeId: agent.staffType && agent.staffType.id,
		code: agent.code,
		officeAddress: agent.officeAddress,
		officeCity: agent.officeCity,
		officeState: agent.officeState,
		officeZip: agent.officeZip,
		photoUrl: agent.photoUrl,
		head: agent.head,
		body: agent.body,
		allowModifyBio: agent.allowModifyBio,
		hasOwnSite: agent.hasOwnSite,
		redirectUrl: agent.redirectUrl,
		enableAgentLink: agent.enableAgentLink,
		showOnSignupDropDown: agent.showOnSignupDropDown,
		emailHeader: agent.emailHeader,
		sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
		allowModifyListings: agent.allowModifyListings,
		allowModifySubscribers: agent.allowModifySubscribers,
		languageIds: agent.languageIds,
		admin: agent.admin,
		ownsLeads: agent.ownsLeads,
	};
	agents.push(resource);
	return resolve<AgentResource>(resource);
};

export const updateAgent = (agent: Agent) => {
	const i = agents.findIndex((value) => value.id === agent.id);
        const resource = {
            ...agents[i],
            firstName: agent.firstName,
            lastName: agent.lastName,
            email: agent.email,
            officeId: agent.office && agent.office.id,
            agentPageUrl: agent.agentPageUrl,
            lastLoginDate: agent.lastLoginDate?.toString(),
            designation: agent.designation,
            username: agent.username,
            password: agent.password,
            homePhone: agent.homePhone,
            officePhone: agent.officePhone,
            homeFax: agent.homeFax,
            officeFax: agent.officeFax,
            cellPhone: agent.cellPhone,
            staffTypeId: agent.staffType && agent.staffType.id,
            code: agent.code,
            officeAddress: agent.officeAddress,
            officeCity: agent.officeCity,
            officeState: agent.officeState,
            officeZip: agent.officeZip,
            photoUrl: agent.photoUrl,
            head: agent.head,
            body: agent.body,
            allowModifyBio: agent.allowModifyBio,
            hasOwnSite: agent.hasOwnSite,
            redirectUrl: agent.redirectUrl,
            enableAgentLink: agent.enableAgentLink,
            showOnSignupDropDown: agent.showOnSignupDropDown,
            emailHeader: agent.emailHeader,
            sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
            allowModifyListings: agent.allowModifyListings,
            allowModifySubscribers: agent.allowModifySubscribers,
			admin: agent.admin
        };
        agents[i] = resource;
		return resolve<AgentResource>(resource);
};

export const updateAgents = (agentList: Agent[]) => {
	let resources: AgentResource[] = [];
	agentList.forEach((agent) => {
		const i = agents.findIndex((value) => value.id === agent.id);
			const resource = {
				...agents[i],
				firstName: agent.firstName,
				lastName: agent.lastName,
				email: agent.email,
				officeId: agent.office && agent.office.id,
				agentPageUrl: agent.agentPageUrl,
				lastLoginDate: agent.lastLoginDate?.toString(),
				designation: agent.designation,
				username: agent.username,
				password: agent.password,
				homePhone: agent.homePhone,
				officePhone: agent.officePhone,
				homeFax: agent.homeFax,
				officeFax: agent.officeFax,
				cellPhone: agent.cellPhone,
				staffTypeId: agent.staffType && agent.staffType.id,
				code: agent.code,
				officeAddress: agent.officeAddress,
				officeCity: agent.officeCity,
				officeState: agent.officeState,
				officeZip: agent.officeZip,
				photoUrl: agent.photoUrl,
				head: agent.head,
				body: agent.body,
				allowModifyBio: agent.allowModifyBio,
				hasOwnSite: agent.hasOwnSite,
				redirectUrl: agent.redirectUrl,
				enableAgentLink: agent.enableAgentLink,
				showOnSignupDropDown: agent.showOnSignupDropDown,
				emailHeader: agent.emailHeader,
				sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,
				allowModifyListings: agent.allowModifyListings,
				allowModifySubscribers: agent.allowModifySubscribers,
				admin: agent.admin,
				showImageOnPage: agent.showImageOnPage,
			};
			agents[i] = resource;
			resources.push(resource)
	})
	return resolve<AgentResource[]>(resources);
};

export const deleteAgent = (agent: Agent) => {
    const i = agents.findIndex(value => value.id === agent.id);
    const resource = agents[i];
    agents.splice(i, 1);
    return resolve(resource);
}

export const getBoards = () => {
    return resolve(boards);
};

export const getAgentBoards = () => {
    return resolve(agentBoards);
}

export const createAgentBoard = (agentBoard: NewAgentBoard) => {
    const resource: AgentBoardResource = {
        id: generateId(),
        agentId: agentBoard.agentId,
        agentCode: agentBoard.agentCode,
        boardId: agentBoard.boardId,
    };
    agentBoards.push(resource);
    return resolve<AgentBoardResource>(resource);
};

export const updateAgentBoard = (agentBoard: AgentBoard) => {
    const i = agentBoards.findIndex((value) => value.id === agentBoard.id);
    const resource: AgentBoardResource = {
        ...agentBoards[i],
        agentId: agentBoard.agentId,
        agentCode: agentBoard.agentCode,
        boardId: agentBoard.boardId,
    };
    agentBoards[i] = resource;
    return resolve<AgentBoardResource>(resource);
};

export const deleteAgentBoard = (agentBoard: AgentBoard) => {
    const i = agentBoards.findIndex(value => value.id === agentBoard.id);
    const resource = agentBoards[i];
    agentBoards.splice(i, 1);
    return resolve(resource);
}

export const getOffices = () => {
    return resolve(offices);
}

export const createOffice = (office: NewOffice) => {
	const resource: OfficeResource = {
		id: generateId(),
		name: office.name,
		code: office.code,
		priority: office.priority,
		address: office.address,
		city: office.city,
		state: office.state,
		zip: office.zip,
		phone: office.phone,
		fax: office.fax,
		email: office.email,
		photoUrl: office.photoUrl,
		header: office.header,
		description: office.description,
	};
	offices.push(resource);
	return resolve<OfficeResource>(resource);
};

export const updateOffice = (office: Office) => {
	const i = offices.findIndex((value) => value.id === office.id);
		const resource = {
			...offices[i],
			name: office.name,
			code: office.code,
			priority: office.priority,
			address: office.address,
			city: office.city,
			state: office.state,
			zip: office.zip,
			phone: office.phone,
			fax: office.fax,
			email: office.email,
			photoUrl: office.photoUrl,
			header: office.header,
			description: office.description,
		};
		offices[i] = resource;
		return resolve<OfficeResource>(resource);
};

export const deleteOffice = (office: Office) => {
    const i = offices.findIndex(value => value.id === office.id);
    const resource = offices[i];
    offices.splice(i,1);
    return resolve(resource);
}

export const getOfficeBoards = () => {
    return resolve(officeBoards);
}

export const createOfficeBoard = (officeBoard: NewOfficeBoard) => {
	const resource: OfficeBoardResource = {
		id: generateId(),
		officeId: officeBoard.officeId,
		officeCode: officeBoard.officeCode,
		boardId: officeBoard.boardId,
	};
	officeBoards.push(resource);
	return resolve<OfficeBoardResource>(resource);
};

export const updateOfficeBoard = (officeBoard: OfficeBoard) => {
	const i = officeBoards.findIndex((value) => value.id === officeBoard.id);
	const resource: OfficeBoardResource = {
		...officeBoards[i],
		officeId: officeBoard.officeId,
		officeCode: officeBoard.officeCode,
		boardId: officeBoard.boardId,
	};
	officeBoards[i] = resource;
	return resolve<OfficeBoardResource>(resource);
};

export const deleteOfficeBoard = (officeBoard: OfficeBoard) => {
    const i = officeBoards.findIndex(value => value.id === officeBoard.id);
    const resource = officeBoards[i];
    officeBoards.splice(i, 1);
    return resolve(resource);
}

export const getCities = () => {
    return resolve(cities);
}

export const getNeighborhoods = () => {
    return resolve(neighborhoods);
}

export const getAggregateStats = () => {
    return resolve(aggregateStats);
}

export const getIntegrations = () => {
    return resolve(integrations);
}

export const saveIntegration = (integration: IntegrationPartial) => {
    let resource: IntegrationResource;
    if (integration.id) {
        const index = integrations.findIndex((value) => value.id === integration.id);
        resource = {
            ...integrations[index],
            integrationTypeId: integration.integrationType.id,
            name: integration.name,
            data: integration.data,
        }
        integrations[index] = resource;
    } else {
        resource = {
            id: generateId(),
            integrationTypeId: integration.integrationType.id,
            name: integration.name,
            data: integration.data,
            createdOn: generateTimestamp(),
        }
        integrations.push(resource)
    }
    return resolve<IntegrationResource>(resource);
}

export const testIntegration = (integration: IntegrationPartial) => {
    return resolve(undefined)
}

export const getIntegrationCalendars = (integrationId: number) => {
    return resolve(integrationCalendars.filter((integrationCalendar) => integrationCalendar.integrationId === integrationId));
}

export const deleteIntegration = (integration: IntegrationPartial) => {
    const index = integrations.findIndex((value) => value.id === integration.id);
    const resource = integrations[index];
    return resolve<IntegrationResource>(resource);
}

export const getSiteLinks = () => {
    return resolve(siteLinks);
}

// Mock doesnt require a signature; return an empty object
export const getUploadSignature = (uploadPreset: string) => {
    return resolve({});
}

export const getGeocode = (address?: string) => {
    // 2560 Ninth St, Berkeley, CA 94710
    return resolve<[ number, number ]>([ -122.291451, 37.859249 ]);
}

export const getPages = () => {
    return resolve(pages);
}

export const savePage = (page: PagePartial) => {
    const resource: PageResource = {
        id: page.id,
        typeId: page.type.id,
        path: page.path,
        title: page.title,
        head: page.head,
        enabled: page.enabled,
    };
    const i = pages.findIndex((value) => value.id === page.id);
    pages[i] = resource;
    return resolve<PageResource>(resource);
}

export const getLanguages = () => {
    return resolve(languages);
}

export const exchangeNylasToken = async (obj: NylasTokenRequest): Promise<NylasTokenResponse> => {
	try {
		const body: NylasTokenRequest = {
			grant_type: 'authorization_code',
			code_verifier: 'nylas',
			...obj,
		}
		const request = await fetch('https://api.us.nylas.com/v3/connect/token', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body),
		});
		if (!request.ok) {
			const errorData = await request.json();
			throw new Error(errorData.error_description || 'Failed to exchange token');
		}
		const data = await request.json();
		return data as NylasTokenResponse;
	} catch(error) {
		if (error instanceof Error) {
			throw new Error(`Failed to exchange Nylas token: ${error.message}`);
		}
		throw new Error('An unexpected error occurred while exchanging Nylas token');
	}
}