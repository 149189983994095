import React from "react";
import * as Mui from "@material-ui/core";
import { Logo } from "../logo";
import { IntegrationType } from "type/integration-type";
import * as env from "shared/env";

interface Props {
	integrationType: IntegrationType;
	onClose: Function;
}

class Component extends React.Component<Props> {

	public render() {
		const { integrationType } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Logo integrationType={integrationType} />
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Typography>
						Connect your email/calendar to send messages and sync events.
					</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Button
						variant="contained"
						color="secondary"
						style={{ marginRight: 8 }}
						href={this.authenticationUrl}
					>
						Add {integrationType.name}
					</Mui.Button>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

	private get authenticationUrl() {
		const nylasClientId = process.env.REACT_APP_NYLAS_CLIENT_ID_V3 || "";
		const redirectUri = window.location.origin + window.location.pathname;
		return `https://api.us.nylas.com/v3/connect/auth?client_id=${nylasClientId}&redirect_uri=${redirectUri}&response_type=code&access_type=online&state=sQ6vFQN`;
	}
}

export const EmailCalendarIntegration = Component;