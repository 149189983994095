import { RootState } from "redux/store";
import { createSelector } from "reselect";
import moment, { Moment } from "moment";
import { Campaign } from "model/campaign";
import { EmailMarketingType } from "type/email-marketing-type";
import { CampaignStatus } from "type/campaign-status";
import { DynamicContentType } from "type/dynamic-content";
import { CampaignSubscription } from "model/campaign-subscription";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";
import { CampaignEmail } from "model/campaign-email";
import { CampaignEmailStatus } from "type/campaign-email-status";
import { CampaignSubscriptionStat } from "model/campaign-subscription-stat";
import { CampaignStat } from "model/campaign-stat";
import { CampaignsSettings } from "model/campaigns-settings";
import { CampaignRoute } from "model/campaign-route";
import { CampaignMessage } from "model/campaign-message";
import { CampaignMessageStatus } from "type/campaign-message-status";
import { CampaignMessageType } from "type/campaign-message";
import { TimeUnitType } from "type/time-unit";
import { CampaignCampaignMessageStat } from "model/campaign-campaign-message-stat";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { City } from "model/city";
import { Client } from "model/client";
import { EmailClick } from "model/email-click";
import { IntegrationCalendar } from "model/integration-calendar";
import { Integration } from "model/integration";
import { IntegrationType } from "type/integration-type";
import { EmailSent } from "model/email-sent";
import { Language } from "model/language";
import { LeadActivity } from "model/lead-activity";
import { LeadActivityType } from "type/lead-activity-type";
import { TextMessageActivity } from "model/text-message-activity";
import { LeadActivityStat } from "model/lead-activity-stat";
import { LeadAnniversaryType } from "type/lead-anniversary";
import { LeadAnniversary } from "model/lead-anniversary";
import { LeadImport } from "model/lead-import";
import { LeadTagType } from "type/lead-tag";
import { ImportError } from "type/import-error";
import { LeadPipelineType } from "type/lead-pipeline";
import { LeadLastActivity } from "model/lead-last-activity";
import { LeadNote } from "model/lead-note";
import { LeadNoteType } from "type/lead-note-type";
import { LeadPhone } from "model/lead-phone";
import { GeographicRoute } from "model/geographic-route";
import { LeadPhoneType } from "type/lead-phone";
import { LeadPipeline } from "model/lead-pipeline";
import { Lead } from "model/lead";
import { LeadStatus } from "type/lead-status";
import { LeadRanking } from "type/lead-ranking";
import { Sort } from "component/shared/table";
import { LeadColumnType } from "type/lead-column";
import { LeadsPageFilters } from "model/leads-page-filters";
import { LeadTag } from "model/lead-tag";
import { Listing } from "model/listing";
import { formatPrice } from "shared/price-formatter";
import { MarketListing } from "model/market-listing";
import { MarketReportSubscription } from "model/market-report-subscription";
import { MarketReport } from "model/market-report";
import { MarketReportType } from "type/market-report-type";
import { Market } from "model/market";
import { Neighborhood } from "model/neighborhood";
import { OfficeBoard } from "model/office-board";
import { Office } from "model/office";
import { PostalCode } from "model/postal-code";
import { PageType } from "type/page-type";
import { Page } from "model/page";
import { Quota } from "model/quota";
import { Reseller } from "model/reseller";
import { SavedListing } from "model/saved-listing";
import { SavedSearch } from "model/saved-search";
import { numberedDateFormatter } from "shared/date-formatter";
import { SiteLink } from "model/site-link";
import { SiteLinkType } from "type/site-link-type";
import { Task } from "model/task";
import { TasksPageFiltersState } from "./slice/tasks-page";
import { UserAuth } from "model/user-auth";
import { TaskStatus } from "type/task-status";
import { TaskType } from "type/task-type";
import { Board } from "model/board";
import { KestrelVersion } from "type/kestrel-version";
import { ListingDetailPageLayoutType } from "type/listing-detail-page-layout";
import { LeadCaptureSidebarPositionType } from "type/lead-capture-sidebar-position";
import { LeadCaptureStrengthType } from "type/lead-capture-strength";
import { SearchPagePromptType } from "type/search-page-prompt";
import { SearchResultsPromptType } from "type/search-results-prompt";
import { SearchDetailPromptType } from "type/search-detail-prompt";
import { ActiveResultsSortType } from "type/active-results-sort";
import { EurekaResultsSortType } from "type/eureka-results-sort";
import { FeaturedSortCodeType } from "type/featured-sort-code";
import { ListingResultsDisplayType } from "type/listing-results-display";
import { LimitSoldResultsByMonthsType } from "type/limit-sold-results-by-months";
import { FeaturedPendingResultsType } from "type/featured-pending-results";
import { MapSearchMapType } from "type/map-search-map-type";
import { OpenHouseDisplayType } from "type/open-house-display";
import { OpenHouseSearchType } from "type/open-house-search";
import { MapPositionType } from "type/map-position";
import { WebsitePlatformType } from "type/website-platform";
import { User } from "model/user";
import { UserType } from "type/user-type";
import { AggregateStat } from "model/aggregate-stat";
import { Agent } from "model/agent";
import { AgentStaffType } from "type/agent-staff";
import { AgentBoard } from "model/agent-board";
import { ViewType } from "type/view-type";
import { RoundRobinRoute } from "model/round-robin-route";
import { PropertyType } from "type/property-type";
import { AgentSortOrderType } from "type/agent-sort-order";
import { TextMessageBrandingStatus } from "type/text-message-branding-status";
import { AgentColumnType } from "type/agent-column-type";
import { MarketSettings } from "model/market-settings";

// AdminMode

export const getAdminMode = (state: RootState) => {
	return state.adminMode.enabled;
};

export const getViewType = (state: RootState) => {
	let result = ViewType.getById(state.adminMode.view);
	if (!result) {
		result = state.adminMode.enabled ? ViewType.ADMIN : ViewType.CLIENT;
	}
	return result;
};

// AgentBoards 

export const getAgentBoardById = (
	state: RootState,
	id?: number
): AgentBoard | undefined => {
	if (id) {
		const agentBoard = state.agentBoards.byId[id];
		if (agentBoard) {
			return {
				...agentBoard,
			};
		}
	}
};

export const getAgentBoardsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids
		.map((id) => getAgentBoardById(state, id))
		.filter(Boolean) as AgentBoard[];
};

export const getAgentBoards = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.agentBoards.byId).map((id) => parseInt(id));
		return getAgentBoardsByIds(state, ids);
	}
);

export const getAgentBoardsForAgent = (state: RootState, agentId: number) => {
	const ids = state.agentBoards.byAgentId[agentId];
	return getAgentBoardsByIds(state, ids);
};


// Agents

export const getAgentById = (state: RootState, id?: number): Agent | undefined => {
	if (id) {
		const agent = state.agents.byId[id];
		if (agent) {
			let name = "";
			if (agent.firstName) {
				name += agent.firstName;
				if (agent.lastName) {
					name += " ";
					name += agent.lastName;
				} 
			}
			let label = "";
			if (agent.email) {
				label = agent.email;
			}
			if (name) {
				label = name;
			}
		
			return {
				...agent,
				name,
				label,
				lastLoginDate: agent.lastLoginDate ? moment(new Date(agent.lastLoginDate)) : undefined,
				office: getOfficeById(state, agent.officeId),
				staffType: AgentStaffType.getById(agent.staffTypeId),
				languageIds: agent.languageIds || [],
				admin: agent.admin ? agent.admin : false,
			};
		}
	}
};

export const getAgentsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getAgentById(state, id)).filter(Boolean) as Agent[];
};

export const getAgents = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.agents.byId).map((id) => parseInt(id));
		return getAgentsByIds(state, ids);
	}
);

export const getAgentsByCustomSort = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.agents.byId).map((id) => parseInt(id));
		const agents = getAgentsByIds(state, ids);
		return agents.sort(function(a, b) {
			const sortOrderA = a.sortOrder ?? Number.MAX_VALUE;
			const sortOrderB = b.sortOrder ?? Number.MAX_VALUE;
			return sortOrderA - sortOrderB;
		});
	}
);

export const getAgentsWithLeads = (state: RootState) => {
	return getAgents(state).filter((agent) => agent.ownsLeads)
}

export const getAgentTableColumns = (state: RootState): AgentColumnType[] => {
	return state.agentPage.columnTypeIds.map((id) => {
		return AgentColumnType.getById(id);
	}).filter(Boolean) as AgentColumnType[];
}

// Postal Codes

export const getPostalCodeById = (state: RootState, id?: number): PostalCode | undefined => {
	if (id) {
		const postalCode = state.postalCodes.byId[id];
		if (postalCode) {
			return {
				...postalCode,
			};
		}
	}
};

export const getPostalCodeByIds = (state: RootState, ids: number[]) => {
	ids = ids || [];
	return ids.map((id) => getPostalCodeById(state, id)).filter(Boolean);
};

export const getPostalCodes = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.postalCodes.byId).map((id) => parseInt(id));
		return getPostalCodeByIds(state, ids);
	}
);





// Geographic Routes

export const getGeographicRouteById = (
	state: RootState,
	id?: number
): GeographicRoute | undefined => {
	if (id) {
		const geographicRoute = state.geographicRoutes.byId[id];
		const agent = getAgentById(state, geographicRoute.agentId);
		if (geographicRoute && agent) {
			return {
				...geographicRoute,
				agent,
				createdOn: moment(new Date(geographicRoute.createdOn)),
				updatedOn: moment(new Date(geographicRoute.updatedOn)),
			};
		}
	}
};

export const getGeographicRoutesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getGeographicRouteById(state, id)).filter(Boolean) as GeographicRoute[];
};

export const getGeographicRoutes = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.geographicRoutes.byId).map((id) => parseInt(id));
		return getGeographicRoutesByIds(state, ids);
	}
);

// Round Robin Routes

export const getRoundRobinRouteById = (
	state: RootState,
	id?: number
): RoundRobinRoute | undefined => {
	if (id) {
		const roundRobinRoute = state.roundRobinRoutes.byId[id];
		const agent = getAgentById(state, roundRobinRoute.agentId);
		if (roundRobinRoute && agent) {
			return {
				...roundRobinRoute,
				agent,
				createdOn: moment(new Date(roundRobinRoute.createdOn)),
			};
		}
	}
};

export const getRoundRobinRoutesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getRoundRobinRouteById(state, id)).filter(Boolean) as RoundRobinRoute[];
};

export const getRoundRobinRoutes = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.roundRobinRoutes.byId).map((id) => parseInt(id));
		return getRoundRobinRoutesByIds(state, ids);
	}
);

// AggregateStats

export const getAggregateStatById = (state: RootState, id?: string): AggregateStat | undefined => {
	if (id) {
		const aggregateStat = state.aggregateStats.byId[id];
		if (aggregateStat) {
			let leadActions = 0;
			let siteSearches = 0; 
			if (aggregateStat.moreInfoRequests) {
				leadActions += aggregateStat.moreInfoRequests;
			}
			if (aggregateStat.scheduleShowingRequests) {
				leadActions += aggregateStat.scheduleShowingRequests;
			}
			if (aggregateStat.valuationRequests) {
				leadActions += aggregateStat.valuationRequests;
			}
			if (aggregateStat.userRegistrations) {
				leadActions += aggregateStat.userRegistrations;
			}
			if (aggregateStat.newSubscribers) {
				leadActions += aggregateStat.newSubscribers;
			}
			if (aggregateStat.contactRequests) {
				leadActions += aggregateStat.contactRequests;
			}
			if (aggregateStat.listingSaves) {
				leadActions += aggregateStat.listingSaves;
			}
			if (aggregateStat.newListingSearchSaves) {
				leadActions += aggregateStat.newListingSearchSaves;
			}
			if (aggregateStat.listingSearches) {
				siteSearches += aggregateStat.listingSearches;
			}
			if (aggregateStat.listingPolygonSearches) {
				siteSearches += aggregateStat.listingPolygonSearches;
			}
			return {
				id: aggregateStat.id, 
				dateStamp: moment(new Date(aggregateStat.dateStamp)),
				moreInfoRequests: aggregateStat.moreInfoRequests || 0,
				scheduleShowingRequests: aggregateStat.scheduleShowingRequests || 0,
				valuationRequests: aggregateStat.valuationRequests || 0,
				userRegistrations: aggregateStat.userRegistrations || 0,
				newSubscribers: aggregateStat.newSubscribers || 0,
				contactRequests: aggregateStat.contactRequests || 0,
				listingSaves: aggregateStat.listingSaves || 0,
				newListingSearchSaves:  aggregateStat.newListingSearchSaves || 0,
				totalListingSearchSaves: aggregateStat.totalListingSearchSaves || 0,
				listingViews: aggregateStat.listingViews || 0,
				listingSearches: aggregateStat.listingSearches || 0,
				listingPolygonSearches: aggregateStat.listingPolygonSearches || 0,
				leadActions: leadActions, 
				siteSearches: siteSearches, 
			}
		}
	}
};

export const getAggregateStatsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getAggregateStatById(state, id)).filter(Boolean) as AggregateStat[];
};

export const getAggregateStats = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.aggregateStats.byId);
		return getAggregateStatsByIds(state, ids); 
	}
);

export const getChronologicalAggregateStats = (state: RootState) => {
	const aggregateStats = getAggregateStats(state);
	if (aggregateStats.length > 1) {
		aggregateStats.sort((a, b)=>{
			return b.dateStamp.valueOf() - a.dateStamp.valueOf();
		})
	}
	return aggregateStats;
};

export const getLatestAggregateStat = createSelector(
	(state: RootState) => state,
	(state) => {
		return getChronologicalAggregateStats(state)[0];
	}
);

export const getLatestExtrapolatedAggregateStat = createSelector(
	(state: RootState) => state,
	(state) => {
		const aggregateStat = getLatestAggregateStat(state);
		if (aggregateStat) {
			const now = moment();
			const currentDayOfMonth = now.date();
			const totalDaysInMonth = now.daysInMonth();
			const excluded = [
				"id",
				"dateStamp",
				"totalListingSearchSaves",
				"subscribers",
			];
			Object.keys(aggregateStat).forEach((key) => {
				if (!excluded.includes(key)) {
					// @ts-ignore
					const value = aggregateStat[key];
					const newValue = Math.round((value / currentDayOfMonth) * totalDaysInMonth);
					// @ts-ignore
					aggregateStat[key] = newValue;
				}
			});
		}
		return aggregateStat;
	}
);

// Authentication

export const getAuthError = (state: RootState) => {
	return state.authentication.error;
};

export const getUserUnsafe = (state: RootState): User | null | undefined => {
	if (state.authentication.user === undefined) {
		return;
	}
	if (state.authentication.user === null) {
		return null;
	}
	if (state.authentication.user) {
		const user = state.authentication.user;
		const oneUser = getOneUser(state);
		let onePermissions = {};
		if (oneUser) {
			onePermissions = oneUser.permissions;
		}
		const kestralVersion = KestrelVersion.getById(user.kestrelVersion);
		/**
		 * admin defaulted to false in order to handle user.admin being undefined 
		 * This can be removed once user.admin is required.
		 */
		let admin = user.admin || false;
		if (user.clientId && !user.agentId) {
			admin = true;
		}
		const textAccountStatus = user.textMessageBrandingStatus ? TextMessageBrandingStatus.getById(user.textMessageBrandingStatus) : undefined;
		return {
			textMessagePhoneNumber: "",
			...user,
			ownsLeads: user.ownsLeads,
			mapSearchMapType:
				MapSearchMapType.getById(user.mapSearchMapTypeId) ||
				MapSearchMapType.ROADMAP,
			kestrelVersion: kestralVersion ? kestralVersion : KestrelVersion.OFF,
			listingDetailSidebarPosition:
				LeadCaptureSidebarPositionType.getById(user.listingDetailSidebarPosition) ||
				LeadCaptureSidebarPositionType.SIDEBAR_RIGHT,
			settingListingDetailPageStyle: ListingDetailPageLayoutType.getById(
				user.settingListingDetailPageStyle
			),
			leadCaptureType: LeadCaptureStrengthType.getById(user.leadCaptureType),
			searchPagePromptType: SearchPagePromptType.getById(
				user.searchPagePromptType
			),
			searchResultsPromptType: SearchResultsPromptType.getById(
				user.searchResultsPromptType
			),
			searchDetailPromptType: SearchDetailPromptType.getById(
				user.searchDetailPromptType
			),
			contractStart: moment(new Date(user.contractStart)),
			accountPaidDate: user.accountPaidDate
				? moment(new Date(user.accountPaidDate))
				: undefined,
			activeResultsSort: ActiveResultsSortType.getByIdOrDefault(user.activeResultsSortId),
			automatedFeaturedSolds: !!user.automatedFeaturedSolds,
			showSupplementalPropertiesOnFeatured: !!user.showSupplementalPropertiesOnFeatured,
			eurekaResultsSort: EurekaResultsSortType.getByIdOrDefault(user.eurekaResultsSortId),
			featuredSortCode: FeaturedSortCodeType.getByIdOrDefault(user.featuredSortCodeId),
			hideSoldSearch: !!user.hideSoldSearch,
			listingResultsDisplay: ListingResultsDisplayType.getByIdOrDefault(user.listingResultsDisplayId),
			limitSoldResultsByMonths: LimitSoldResultsByMonthsType.getByIdOrDefault(user.limitSoldResultsByMonthsId),
			featuredPendingResults: FeaturedPendingResultsType.getByIdOrDefault(user.featuredPendingResultsId),
			showClientListingsFirst: !!user.showClientListingsFirst,
			soldsOnFeatured: !!user.soldsOnFeatured,
			openHouseDisplayType: OpenHouseDisplayType.getByIdOrDefault(user.openHouseDisplayTypeId),
			openHouseSearchType: OpenHouseSearchType.getByIdOrDefault(user.openHouseSearchTypeId),
			mapPosition: MapPositionType.getByIdOrDefault(user.mapPosition),
			listingDetailModal: !!user.listingDetailModal,
			listingAgentRouting: !!user.listingAgentRouting,
			geographicRouting: !!user.geographicRouting,
			roundRobinRouting: !!user.roundRobinRouting,
			resultsPerPage: user.resultsPerPage,
			websitePlatform: WebsitePlatformType.getOrCreateById(user.websitePlatform),
			agentSortOrder: AgentSortOrderType.getByIdOrDefault(user.agentSortOrder),
			get type() {
				return user.agentId ? UserType.AGENT : UserType.CLIENT;
			},
			get name() {
				return user.firstName + " " + user.lastName;
			},
			reseller: {
				...user.reseller,
			},
			agent: getAgentById(state, user.agentId),
			admin: admin,
			textMessageAreaCode: user.textMessageAreaCode,
			textMessageCallForwarding: user.textMessageCallForwarding,
			tcpaCompliantTimeZone: user.tcpaCompliantTimeZone,
			textMessageOptIn: user.textMessageOptIn,
			dripMMSOptOutDate: user.dripMMSOptOutDate,
			textMessageBrandingStatus: textAccountStatus,
			onePermissions: onePermissions,
			lastMobileLogin: user.lastMobileLogin
			? moment(new Date(user.lastMobileLogin))
			: undefined,
			permissions: {
				...user.permissions,
				get report() {
					return (
						user.permissions.listingReport ||
						user.permissions.openHomeReport ||
						user.permissions.marketReport
					);
				},
				get leads() {
					return (
						user.permissions.leadSaveSearch ||
						user.permissions.leadSaveListing ||
						user.permissions.leadEmailUpdates ||
						user.permissions.leadPropertyOrganizer ||
						user.permissions.leadRequestMoreInfo ||
						user.permissions.leadScheduleShowing ||
						user.permissions.leadUserRegistration ||
						user.permissions.leadValuationRequest ||
						user.permissions.leadContactForm
					);
				},
				get leadRouting() {
					return (
						user.permissions.geographicRouting ||
						user.permissions.roundRobinRouting ||
						user.permissions.listingAgentRouting
					)
				},
				get crm() {
					return (
						user.permissions.crmNotes ||
						user.permissions.crmTags ||
						user.permissions.crmTasks ||
						user.permissions.crmPhones ||
						user.permissions.crmAnniversary ||
						user.permissions.crmPipeline
					);
				},
				get crmUpgrade() {
					return (
						!this.crm &&
						!user.agentId &&
						user.reseller.direct &&
						!user.permissions.oneCrm &&
						!user.permissions.broker
					);
				},
				/**
				 * Possible should only be available to CRM
				 */
				get leadLastActive() {
					return true;
				},
				get crmTasks() {
					return user.permissions.crmTasks;
				},
				get crmBulkActions() {
					return this.crm;
				},
				get mobileApp() {
					return (
						!user.permissions.oneCrm
					);
				},
				get emailCalendarIntegration() {
					return (
						user.permissions.emailCalendarIntegration &&
						user.permissions.blastCampaigns
					);
				},
				get automatedGreetings() {
					return (
						user.permissions.emailCalendarIntegration &&
						user.permissions.crmAnniversary
					);
				},
				get kestrelAll() {
					return user.permissions.kestrelAll
				}
			}
		};
	}
};


export const getUser = (state: RootState) => {
	return getUserUnsafe(state) as User;
}


// Boards

export const getBoardById = (state: RootState, id?: number): Board | undefined => {
	if (id) {
		return state.boards.byId[id];
	}
};

export const getBoardsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getBoardById(state, id)).filter(Boolean) as Board[];
};

export const getBoards = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.boards.byId).map((id) => parseInt(id));
		return getBoardsByIds(state, ids); 
	}
);

export const getBoardsForUser = (state: RootState) => {
	const boardIds = state.authentication.user ? state.authentication.user.boardIds : [];
	return getBoardsByIds(state, boardIds);
};

export const isCanadianOnly = (state: RootState) => {
	return getBoardsForUser(state).every((board) => board.isCanadian);
};

export const canDisplayExternalLinksOnPropResultsAndDetails = (state: RootState) => {
	return getBoardsForUser(state).every((board) => board.externalLinkDisplayType === 1);
};

export const canShowPhotoLayoutOptions = (state: RootState) => {
	return getBoardsForUser(state).every((board) => !board.isContiguous);
};

export const canShowSupplementalOnSeparatePage = (state: RootState) => {
	return getBoardsForUser(state).every((board) => !board.isContiguous);
}

export const canShowSupplementalListings = (state: RootState) => {
	return getBoardsForUser(state).every((board) => board.allowsSupplementalListings === true);
};

// Campaigns

export const getCampaignById = (state: RootState, id?: number): Campaign | undefined => {
	if (id) {
		const campaign = state.campaigns.byId[id];
		if (campaign) {
			const status = campaign.status && CampaignStatus.getById(campaign.status);
			let emailMarketingType = campaign.emailMarketingTypeId && EmailMarketingType.getById(campaign.emailMarketingTypeId);
			if (!emailMarketingType) {
				emailMarketingType = EmailMarketingType.DRIP;
			}
			const dynamicContentType = campaign.campaignType ? campaign.campaignType : ""
			const campaignType = DynamicContentType.getById(dynamicContentType);

			return {
				...campaign,
				status: status ? status : CampaignStatus.INACTIVE,
				get campaignCampaignMessages() {
					return getCampaignCampaignMessagesByCampaignId(state, campaign.id);
				},
				get subscriptions() {
					return getCampaignSubscriptionsForCampaign(state, campaign.id);
				},
				campaignType,
				emailMarketingType,
				dateCreated: moment(new Date(campaign.dateCreated)),
				dateModified: campaign.dateModified ? moment(new Date(campaign.dateModified)) : undefined,
				inactiveYn: campaign.inactiveYn,
			}
		}
	}
};

export const getCampaignsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getCampaignById(state, id)).filter(Boolean) as Campaign[];
};

export const getCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.campaigns.byId).map((id) => parseInt(id));
		return getCampaignsByIds(state, ids); 
	}
);

const getActiveCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		return getCampaigns(state)
			.filter(campaign => campaign && campaign.status && (campaign.status === CampaignStatus.ACTIVE) && !campaign.inactiveYn)
		;
	}
);

export const getDripCampaigns = (state: RootState, campaigns: Campaign[]) => {
	campaigns.forEach((campaign) => {
		campaign.campaignStat = getCampaignStatsByCampaignId(state, campaign.id);
	});
	campaigns.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		} else if (a.name > b.name) {
			return 1;
		}
		return 0;
	});
	return campaigns;
};

export const getActiveDripCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		const campaigns = getActiveCampaigns(state).filter(campaign => campaign.emailMarketingType.drip);
		return getDripCampaigns(state, campaigns);
	}
);

export const getAllDripCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		const campaigns = getCampaigns(state).filter(campaign => campaign.emailMarketingType.drip && !campaign.inactiveYn);
		return getDripCampaigns(state, campaigns);
	}
);

export const getAnniversaryCampaignByType = (state: RootState, type: EmailMarketingType) => {
	return getCampaigns(state).find(campaign => {
		if (campaign.emailMarketingType === type) {
			let campaignMessage = null;
			const campaignCampaignMessage = campaign.campaignCampaignMessages[0];
			if (campaignCampaignMessage) {
				campaignMessage = campaignCampaignMessage.campaignMessage;
				if (campaignMessage) {
					return true;
				}
			}
		}
		return false;
	});
};

const getBlastCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		return getActiveCampaigns(state).filter(campaign => campaign.emailMarketingType.blast);
	}
);

export const getSentBlastCampaigns = createSelector(
	(state: RootState) => state,
	(state) => {
		const draftCampaignIds = getDraftBlastCampaigns(state).map(campaign => campaign.id);
		return getBlastCampaigns(state).filter(campaign => !draftCampaignIds.includes(campaign.id));
	}
);

export const getDraftBlastCampaigns = createSelector(
    (state: RootState) => state,
    (state) => {
        return getBlastCampaigns(state).filter(campaign => {
			let scheduled;
			 if (campaign.campaignCampaignMessages.length && campaign.campaignCampaignMessages[0].campaignMessage.timeToSendNumber) {
				scheduled = true;
			 }
			const subsriptionsAdded = campaign.subscriptions.length;
			const emailsHaveBeenSent = campaign.subscriptions.some(subscription => subscription.emails.length !== 0);
			if (scheduled) {
				if (!emailsHaveBeenSent) {
					// if scheduled is true and emails have not been sent, then it is a draft
					return true;
				}
			} else if (!subsriptionsAdded) {
				// if there are no subscriptions added, then it is a draft
				return true;
			}
			return false;
        });
    }
); 

export const getUnsubscribedCampaigns = (state: RootState, leadId: number) => {
	const subscriptions = getCampaignSubscriptionsForLead(state, leadId);
	let subscribedCampaignIds: number[] | undefined;
	let unsubscribedCampaigns: Campaign[] | undefined;
	if (subscriptions) {
		subscribedCampaignIds = subscriptions.map((subscription) => subscription.campaign.id);
	}
	unsubscribedCampaigns = getActiveDripCampaigns(state).filter(campaign => {
		if (subscribedCampaignIds !== undefined && subscribedCampaignIds.includes(campaign.id)) {
			return false;
		}
		return true;
	})
	return unsubscribedCampaigns;
};

// CampaignSubscriptions

export const getCampaignSubscriptionById = (state: RootState, id?: number): CampaignSubscription | undefined => {
	if (id) {
		const subscription = state.campaignSubscriptions.byId[id];
		if (subscription) {
			const status = (subscription.status) ? CampaignSubscriptionStatus.getById(subscription.status) : undefined;
			const campaign = getCampaignById(state, subscription.campaignId);
			const lead = getLeadById(state, subscription.leadId);
			if (status && campaign && lead) {
				return {
					...subscription,
					createdOn: moment(new Date(subscription.createdOn)),
					status,
					get stage() {
						return this.emails.length;
					},
					get remaining() {
						let remaining = (this.stage) ? this.stage : 0;
						return Math.max(0, this.campaign.campaignCampaignMessages.length - remaining);
					},
					campaign,
					get emails() {
						return getCampaignEmailsByLeadIdAndCampaignId(state, subscription.leadId, subscription.campaignId);
					},
					lead, 
				}
			}
		}
	}
};

export const getCampaignSubscriptionsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getCampaignSubscriptionById(state, id)).filter(Boolean) as CampaignSubscription[];
};

export const getCampaignSubscriptions = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.campaignSubscriptions.byId).map((id) => parseInt(id));
		return getCampaignSubscriptionsByIds(state, ids); 
	}
);

export const getDripCampaignSubscriptions = createSelector(
	(state: RootState) => state,
	(state) => {
		return getCampaignSubscriptions(state).filter(campaignSubscription => campaignSubscription.campaign.emailMarketingType.drip);
	}
);

export const getBlastCampaignSubscriptions = createSelector(
	(state: RootState) => state,
	(state) => {
		return getCampaignSubscriptions(state).filter(campaignSubscription => campaignSubscription.campaign.emailMarketingType.blast);
	}
);

export const getCampaignSubscriptionsForCampaign = (state: RootState, campaignId: number) => {
	return getCampaignSubscriptions(state).filter((campaignSubscription) => {
		const status = (campaignSubscription.status) ? campaignSubscription.status.deleted : undefined;
		return campaignSubscription.campaign.id === campaignId && !status
	});
};

export const getTotalBlastCampaignSubscriptionsWithin24Hours = createSelector(
	(state: RootState) => state,
	(state) => {
		return getBlastCampaignSubscriptions(state)
			.filter(subscription => {
				const yesterday = moment().subtract(1, "days");
				if (subscription.campaign.dateModified) {
					return subscription.campaign.dateModified.isAfter(yesterday);
				}
				return subscription.campaign.dateCreated.isAfter(yesterday);
			})
			.length
		;
	}
);

export const getCampaignSubscriptionsForLead = (state: RootState, leadId: number) => {
	const ids = state.campaignSubscriptions.byLeadId[leadId];
	return getCampaignSubscriptionsByIds(state, ids);
};

export const getDripCampaignSubscriptionsForLead = (state: RootState, leadId: number) => {
	return getCampaignSubscriptionsForLead(state, leadId).filter(campaignSubscription => campaignSubscription.campaign.emailMarketingType.drip);
};

const getCampaignSubscriptionsForLeads = (state: RootState, leadIds: number[]) => {
	let leadsSubscriptions: CampaignSubscription[] = [];
	leadIds.forEach((leadId) => {
		const subscriptionIds = state.campaignSubscriptions.byLeadId[leadId];
		if (subscriptionIds) {
			subscriptionIds.forEach((id) => {
				const subscription = getCampaignSubscriptionById(state, id);
				if (subscription) {
					leadsSubscriptions.push(subscription);
				}
			});
		}
	});
	return leadsSubscriptions;
};

export const getDripCampaignSubscriptionsForLeads = (state: RootState, leadIds: number[]) => {
	return getCampaignSubscriptionsForLeads(state, leadIds).filter(campaignSubscription => campaignSubscription.campaign.emailMarketingType.drip);
};

export const getActiveDripCampaignSubscriptionsForLead = (state: RootState, leadId: number) => {
	return getDripCampaignSubscriptionsForLead(state, leadId).filter((subscription) => {
		const status = (subscription.status) ? subscription.status.active : undefined;
		return status;
	});
};

// CampaignEmails

export const getCampaignEmailById = (state: RootState, id?: number): CampaignEmail | undefined => {
	if (id) {
		const campaignEmail = state.campaignEmails.byId[id];
		if (campaignEmail) {
			const campaignMessage = getCampaignMessageById(state, campaignEmail.campaignMessageId);
			let status = CampaignEmailStatus.PENDING;
			if (campaignEmail.failedOn) {
				status = CampaignEmailStatus.FAILED;
			}
			if (campaignEmail.deliveredOn) {
				status = CampaignEmailStatus.SUCCESS;
			}
			if (campaignMessage) {
				return {
					...campaignEmail,
					campaignMessage,
					status,
					deliveredOn: campaignEmail.deliveredOn ? moment(new Date(campaignEmail.deliveredOn)) : undefined,
					failedOn: campaignEmail.failedOn ? moment(new Date(campaignEmail.failedOn)) : undefined,
					openedOn: campaignEmail.openedOn ? moment(new Date(campaignEmail.openedOn)) : undefined,
					clickedOn: campaignEmail.clickedOn ? moment(new Date(campaignEmail.clickedOn)) : undefined,
				}
			}
		}
	}
};

export const getCampaignEmailsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getCampaignEmailById(state, id)).filter(Boolean) as CampaignEmail[];
};

export const getCampaignEmails = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.campaignEmails.byId).map((id) => parseInt(id));
		return getCampaignEmailsByIds(state, ids); 
	}
);

export const getCampaignEmailsByLeadId = (state: RootState, leadId: number) => {
	return getCampaignEmails(state).filter(campaignEmail => campaignEmail.leadId === leadId);
};

export const getCampaignEmailsByLeadIdAndCampaignId = (state: RootState, leadId: number, campaignId: number) => {
	return getCampaignEmailsByLeadId(state, leadId).filter(campaignEmail => campaignEmail.campaignId === campaignId);
};

export const getCampaignEmailsByCampaignId = (state: RootState, campaignId: number) => {
	return getCampaignEmails(state).filter(campaignEmail => campaignEmail.campaignId === campaignId);
};

export const getCampaignEmailsStat = (campaignEmails: CampaignEmail[]) => {
	const ids = campaignEmails.map(campaignEmail => campaignEmail.id);
	const leadIds = campaignEmails.map(campaignEmail => campaignEmail.leadId);
	const total = campaignEmails.length;
	const pending = campaignEmails.filter(campaignEmail => campaignEmail.status.pending).length;
	const failures = campaignEmails.filter(campaignEmail => campaignEmail.status.failed).length;
	const successes = campaignEmails.filter(campaignEmail => campaignEmail.status.failed).length;
	const opens = campaignEmails.filter(campaignEmail => campaignEmail.openedOn).length;
	const clicks = campaignEmails.filter(campaignEmail => campaignEmail.clickedOn).length;
	const clickCount = campaignEmails
		.map(campaignEmail => campaignEmail.clicks || 0)
		.reduce((a, b) => a + b, 0)
	;
	const successRate = (successes / total) || 0;
	const failureRate = (failures / total) || 0;
	const openRate = (opens / total) || 0;
	const clickRate = (clicks / total) || 0;
	return {
		ids,
		leadIds,
		total,
		pending,
		failures,
		successes,
		opens,
		clicks,
		clickCount,
		successRate,
		failureRate,
		openRate,
		clickRate,
	};
};


//campaignSubscriptionStats

export const getCampaignSubscriptionStatById = (state: RootState, id?: number): CampaignSubscriptionStat | undefined => {
	if (id) {
		const campaignSubscriptionStat = state.campaignSubscriptionStats.byId[id];
		const lastSent = campaignSubscriptionStat.lastSent ? moment(new Date(campaignSubscriptionStat.lastSent)) : undefined;
		const campaign = getCampaignById(state, campaignSubscriptionStat.campaignId);
		const lead = getLeadById(state, campaignSubscriptionStat.leadId);
		const campaignSubscription = getCampaignSubscriptionById(state, campaignSubscriptionStat.campaignSubscriptionId);
		const messageStatusDate = campaignSubscriptionStat.messageStatusDate ? moment(new Date(campaignSubscriptionStat.messageStatusDate)) : undefined;
		const messageStatus = campaignSubscriptionStat.messageStatus;
		if (campaignSubscriptionStat && campaign && lead && campaignSubscription) {
			return {
				...campaignSubscriptionStat, 
				messageStatusDate,
				messageStatus,
				lastSent: lastSent,
				campaign,
				lead,
				campaignSubscription,
			};
		}
	}
};

export const getCampaignSubscriptionStatByCampaignSubscriptionId = (state: RootState, campaignSubscriptionId: number): CampaignSubscriptionStat | undefined => {
	return getSubscriptionStats(state).find((campaignSubscription) => campaignSubscription.campaignSubscription.id === campaignSubscriptionId);
}

export const getSubscriptionStats = (state: RootState) => {
	return Object.keys(state.campaignSubscriptionStats.byId).map((id) => {
		return getCampaignSubscriptionStatById(state, parseInt(id));
	}).filter(Boolean) as CampaignSubscriptionStat[];
}

// CampaignStats 

export const getCampaignStatById = (state: RootState, id?: number): CampaignStat | undefined => {
	if (id) {
		const campaignStat = state.campaignStats.byCampaignId[id];
		if (campaignStat) {
			return {
				...campaignStat,
			};
		}
	}
};

export const getCampaignStats = (state: RootState) => {
	return Object.keys(state.campaignStats.byCampaignId).map((id) => {
		return getCampaignStatById(state, parseInt(id));
	}).filter(Boolean) as CampaignStat[];
}

export const getCampaignStatsByCampaignId = (state: RootState, campaignId: number) => {
	const campaignStats = getCampaignStats(state).find((campaignStat) => {
		return campaignStat.campaignId === campaignId;
	});
	return campaignStats;
}

// CampaignSettings 

export const getCampaignsSettings = (state: RootState): CampaignsSettings | undefined => {
	const campaignsSettingsState = state.campaignsSettings;
	if (campaignsSettingsState === undefined) {
		return;
	}
	if (campaignsSettingsState) {
		const lead = getLeadById(state, campaignsSettingsState.leadId);
		return {
			dynamicContentAddress: campaignsSettingsState.dynamicContentAddress,
			lead: lead,
		};
	}
};

// CampaignRoutes 

export const getCampaignRouteById = (state: RootState, id?: number): CampaignRoute | undefined => {
	if (id) {
		const campaignRoute = state.campaignRoutes.byId[id];
		const campaign = getCampaignById(state, campaignRoute.campaignId);
		if (campaignRoute && campaign) {
			return {
				...campaignRoute,
				campaign: campaign,
			};
		}
	}
};

export const getCampaignRoutes = (state: RootState) => {
	return Object.keys(state.campaignRoutes.byId).map((id) => {
		return getCampaignRouteById(state, parseInt(id));
	}).filter(Boolean) as CampaignRoute[];
}

// CampaignMessages

export const getCampaignMessageById = (state: RootState, id?: number): CampaignMessage | undefined => {
	if (id) {
		const campaignMessage = state.campaignMessages.byId[id];
		if (campaignMessage && !campaignMessage.isInactiveYn) {
			const status = CampaignMessageStatus.getById(campaignMessage.status);
			const campaignMessageType = CampaignMessageType.getById(campaignMessage.messageType);
			return {
				...campaignMessage,
				messageType: campaignMessageType ? campaignMessageType : CampaignMessageType.EMAIL,
				dateCreated: moment(new Date(campaignMessage.dateCreated)),
				dateModified: campaignMessage.dateModified ? moment(new Date(campaignMessage.dateModified)) : undefined,
				timeToSendUnit: TimeUnitType.getById(campaignMessage.timeToSendUnit),
				status: status ? status : CampaignMessageStatus.ACTIVE,
				attachments: campaignMessage.attachments,
			};
		}
	}
};

export const getCampaignMessagesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getCampaignMessageById(state, id)).filter(Boolean) as CampaignMessage[];
};

export const getCampaignMessages = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.campaignMessages.byId).map((id) => parseInt(id));
		return getCampaignMessagesByIds(state, ids); 
	}
);

// CampaignCampaignMessageStats

export const getCampaignCampaignMessageStatById = (state: RootState, id?: number): CampaignCampaignMessageStat | undefined => {
	if (id) {
		const campaignCampaignMessageStat = state.campaignCampaignMessageStats.byCampaignCampaignMessageId[id];
		if (campaignCampaignMessageStat) {
			return {
				...campaignCampaignMessageStat,
			};
		}
	}
};

export const getCampaignCampaignMessageStats = createSelector(
	(state: RootState) => state,
	(state) => {
		return Object.keys(state.campaignCampaignMessageStats.byCampaignCampaignMessageId).map((id) => {
			return getCampaignCampaignMessageStatById(state, parseInt(id));
		}).filter(Boolean) as CampaignCampaignMessageStat[];
	}
);

export const getCampaignCampaignMessageStatsByCampaignCampaignMessageId = (state: RootState, id: number) => {
	const campaignCampaignMessageStats = getCampaignCampaignMessageStats(state).find((stat) => {
		return stat.campaignCampaignMessageId === id;
	});
	return campaignCampaignMessageStats;
};

// CampaignCampaignMessages

export const getCampaignCampaignMessageById = (state: RootState, id?: number): CampaignCampaignMessage | undefined => {
	if (id) {
		const campaignCampaignMessage = state.campaignCampaignMessages.byId[id];
		
		if (campaignCampaignMessage) {
			const campaignMessage = getCampaignMessageById(state, campaignCampaignMessage.campaignMessageId);
			if (campaignMessage) {
				const stats = getCampaignCampaignMessageStatsByCampaignCampaignMessageId(state, campaignCampaignMessage.id);
				return {
					...campaignCampaignMessage,
					campaignMessage: campaignMessage,
					dateCreated: moment(new Date(campaignCampaignMessage.dateCreated)),
					stats: stats,
				};
			}
		}
	}
};

export const getCampaignCampaignMessages = createSelector(
	(state: RootState) => state,
	(state) => {
		return sortCampaignCampaignMessages(
			Object.keys(state.campaignCampaignMessages.byId).map((id) => {
				return getCampaignCampaignMessageById(state, parseInt(id));
			}).filter(Boolean) as CampaignCampaignMessage[]
		);
	}
);

export const getCampaignCampaignMessagesByCampaignMessageId = (state: RootState, id: number) => {
	return getCampaignCampaignMessages(state).filter((campaignCampaignMessage) => campaignCampaignMessage.campaignMessageId === id);
};

export const getCampaignCampaignMessagesByCampaignId = (state: RootState, id: number) => {
	return getCampaignCampaignMessages(state).filter((campaignCampaignMessage) => campaignCampaignMessage.campaignId === id);
};

const sortCampaignCampaignMessages = (campaignCampaignMessages: CampaignCampaignMessage[]) => {
	return campaignCampaignMessages.sort((a, b) => {
		if (a.messageOrder < b.messageOrder) {
			return -1;
		} else if (a.messageOrder > b.messageOrder) {
			return 1;
		}
		return 0;
	});
}

export const getCampaignCampaignMessagesCount = (state: RootState, campaignId: number) => {
	return getCampaignCampaignMessagesByCampaignId(state, campaignId).length;
}

// Cities

export const getCityById = (state: RootState, id: number): City | undefined => {
	if (id) {
		const city = state.cities.byId[id];
		if (city) {
			return {
				...city,
			}
		}
	}
};

export const getCitiesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getCityById(state, id)).filter(Boolean) as City[];
};

export const getCities = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.cities.byId).map((id) => parseInt(id));
		return getCitiesByIds(state, ids); 
	}
);

// Clients

export const getClientById = (state: RootState, id?: number ): Client | undefined => {
	if (id) {
		const client = state.clients.byId[id];
		if (client) {
			return {
				...client,
			};
		}
	}
}

// EmailClicks

export const getEmailClickById = (state: RootState, id?: number): EmailClick | undefined => {
	if (id) {
		const emailClick = state.emailClicks.byId[id];
		if (emailClick) {
			const listing = getListingById(state, emailClick.listing);
			return {
				...emailClick,
				clickedOn: moment(new Date(emailClick.clickedOn)),
				listing,
			}
		}
	}
};

export const getEmailClicksByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getEmailClickById(state, id)).filter(Boolean) as EmailClick[];
};

export const getEmailClicks = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.emailClicks.byId).map((id) => parseInt(id));
		return getEmailClicksByIds(state, ids); 
	}
);

export const getEmailClicksForSavedSearch = (state: RootState, savedSearchId: number) => {
	const ids = state.emailClicks.bySavedSearchId[savedSearchId];
	return getEmailClicksByIds(state, ids);
};

const getEmailClicksForLead = (state: RootState, leadId: number) => {
	const ids = state.emailClicks.byLeadId[leadId];
	return getEmailClicksByIds(state, ids);
};

export const getEmailClicksForLeadAndMarketReport = (state: RootState, leadId: number, marketReportId: number) => {
	return getEmailClicksForLead(state, leadId).filter((emailClick) => emailClick.marketReportId === marketReportId);
};

//EmailSents 

export const getEmailSentById = (state: RootState, id?: string): EmailSent | undefined => {
	if (id) {
		const emailSent = state.emailSents.byId[id];
		if (emailSent) {
			return {
				...emailSent,
				sentOn: moment(new Date(emailSent.sentOn)),
			}
		}
	}
};

export const getEmailSentsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getEmailSentById(state, id)).filter(Boolean) as EmailSent[];
};

export const getEmailSents = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.emailSents.byId);
		return getEmailSentsByIds(state, ids); 
	}
);

export const getEmailSentsForSavedSearch = (state: RootState, savedSearchId: number) => {
	const ids = state.emailSents.bySavedSearchId[savedSearchId];
	return getEmailSentsByIds(state, ids);
};

export const getEmailSentsForLead = (state: RootState, leadId: number) => {
	const ids = state.emailSents.byLeadId[leadId];
	return getEmailSentsByIds(state, ids);;
};

export const getEmailSentsForLeadAndMarketReport = (state: RootState, leadId: number, marketReportId: number) => {
	return getEmailSentsForLead(state, leadId).filter((emailSent) => emailSent.marketReportId === marketReportId);
};

// IntegrationCalendar

export const getIntegrationCalendarById = (state: RootState, id: string): IntegrationCalendar | undefined => {
	if (id) {
		const integrationCalendar = state.integrationCalendars.byId[id];
		if (integrationCalendar) {
			return {
				...integrationCalendar,
			}
		}
	}
};

export const getIntegrationCalendarsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getIntegrationCalendarById(state, id)).filter(Boolean) as IntegrationCalendar[];
};

export const getIntegrationCalendars = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.integrationCalendars.byId);
		return getIntegrationCalendarsByIds(state, ids); 
	}
);

export const getIntegrationCalendarsByIntegrationId = (state: RootState, integrationId: number) => {
	return getIntegrationCalendars(state).filter(integrationCalendar => integrationCalendar.integrationId === integrationId);
};

// Integrations

export const getIntegrationById = (state: RootState, id: number): Integration | undefined => {
	if (id) {
		const integration = state.integrations.byId[id];
		if (integration) {
			const integrationType = IntegrationType.getById(integration.integrationTypeId);
			if (integrationType) {
				return {
					...integration,
					data: {
						...integration.data,
					},
					integrationType,
					createdOn: moment(new Date(integration.createdOn)),
					label: integration.name || integrationType.name,
				}
			}
		}
	}
};

export const getIntegrationsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getIntegrationById(state, id)).filter(Boolean) as Integration[];
};

export const getIntegrations = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.integrations.byId).map((id) => parseInt(id));
		return getIntegrationsByIds(state, ids); 
	}
);

export const getIntegrationsNewestToOldest = createSelector(
	(state: RootState) => state,
	(state) => {
		return getIntegrations(state).sort((a, b) => {
			return b.createdOn.valueOf() - a.createdOn.valueOf();
		});
	}
);

export const getIntegrationByType = (state: RootState, integrationType: IntegrationType) => {
	return getIntegrations(state).find(integration => integration.integrationType === integrationType);
};

export const getIntegrationByTypeId = (state: RootState, integrationType: IntegrationType) => {
	return getIntegrations(state).find(integration => integration.integrationType.id === integrationType.id);
  };

export const missingEmailCalendarIntegration = createSelector(
	(state: RootState) => state,
	(state) => {
		return !state.integrations.loading && !getIntegrationByType(state, IntegrationType.EMAIL_CALENDAR);
	}
);

// Languages

export const getLanguageById = (state: RootState, id: number): Language | undefined => {
	if (id) {
		const language = state.languages.byId[id];
		if (language) {
			return {
				...language,
			}
		}
	}
};

export const getLanguagesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLanguageById(state, id)).filter(Boolean) as Language[];
};

export const getLanguages = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.languages.byId).map((id) => parseInt(id));
		return getLanguagesByIds(state, ids);
	}
);

// LeadActivities

export const getLeadActivityById = (state: RootState, id?: string): LeadActivity | undefined => {
	if (!id || !state.leadActivities.byId[id]) {
        return undefined;
    }
	
	if (id) {
		const leadActivity = state.leadActivities.byId[id];
		if (leadActivity) {
			const type = LeadActivityType.getById(leadActivity.typeId);
			const listing = getListingById(state, leadActivity.listing) || undefined;
			let textMessageActivity: TextMessageActivity | undefined;
			if (leadActivity.textMessageActivity) {
				textMessageActivity = {
					...leadActivity.textMessageActivity,
					dateSent: moment(new Date(leadActivity.textMessageActivity.dateSent)),	
				}
			}
			if (type) {
				return {
					...leadActivity,
					createdOn: moment(new Date(leadActivity.createdOn)),
					type,
					listing,
					textMessageActivity,
				};
			}
		}
	}
};

export const getLeadActivitiesByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadActivityById(state, id)).filter(Boolean) as LeadActivity[];
};

export const getLeadActivities = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadActivities.byId);
		return getLeadActivitiesByIds(state, ids); 
	}
);

export const getLeadActivitiesChronological = createSelector(
	(state: RootState) => state,
	(state) => {
		return getLeadActivities(state).sort((act1: LeadActivity, act2: LeadActivity) => {
			return act2.createdOn.valueOf() - act1.createdOn.valueOf()
		})
	}
);

export const getLeadActivitiesForLead = (state: RootState, leadId: number) => {
	const ids = state.leadActivities.byLeadId[leadId];
	return getLeadActivitiesByIds(state, ids);
};

export const getLeadActivitiesForLeadChronological = (state: RootState, leadId: number) => {
	return getLeadActivitiesForLead(state, leadId).sort((act1: LeadActivity, act2: LeadActivity) => {
		return act2.createdOn.valueOf() - act1.createdOn.valueOf()
	})
};

// LeadActivityStats 

export const getLeadActivityStatById = (state: RootState, id?: string): LeadActivityStat | undefined => {
	if (id) {
		const leadActivityStat = state.leadActivityStats.byId[id];
		if (leadActivityStat) {
			return {
				...leadActivityStat,
			};	
		}
	}
};

export const getLeadActivityStatsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadActivityStatById(state, id)).filter(Boolean) as LeadActivityStat[];
};

export const getLeadActivityStats = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadActivityStats.byId);
		return getLeadActivityStatsByIds(state, ids); 
	}
);

// LeadAnniversaries

export const getLeadAnniversaryById = (state: RootState, id?: number): LeadAnniversary | undefined => {
	if (id) {
		const leadAnniversary = state.leadAnniversaries.byId[id];
		if (leadAnniversary) {
			const type = LeadAnniversaryType.getById(leadAnniversary.typeId);
			const lead = getLeadById(state, leadAnniversary.leadId);
			if (type && lead) {
				let upcomingAnniversary;
				const anniversaryThisYear = moment(new Date(leadAnniversary.startDate)).set({"y": moment().year(), "hour": 23, "minute": 59});
				if (anniversaryThisYear < moment()) {
					upcomingAnniversary = anniversaryThisYear.add(1, "y");
				} else {
					upcomingAnniversary = anniversaryThisYear;
				}
				return {
					...leadAnniversary,
					type,
					lead,
					upcomingAnniversary, 
					updatedOn: moment(new Date(leadAnniversary.updatedOn)),
					startDate: moment(new Date(leadAnniversary.startDate)),
					createdOn: moment(new Date(leadAnniversary.createdOn)),
				};
			}
		}
	}
};

export const getLeadAnniversariesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadAnniversaryById(state, id)).filter(Boolean) as LeadAnniversary[];
};

export const getLeadAnniversaries = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadAnniversaries.byId).map((id) => parseInt(id));
		return getLeadAnniversariesByIds(state, ids); 
	}
);

export const getChronologicalLeadAnniversaries = createSelector(
	(state: RootState) => state,
	(state) => {
		const leadAnniversaries = getLeadAnniversaries(state);
		if (leadAnniversaries.length > 1) {
			leadAnniversaries.sort((a,b) => {
				return a.upcomingAnniversary.valueOf() - b.upcomingAnniversary.valueOf();
			})
		}
		return leadAnniversaries;
	}
);

export const getUpcommingLeadAnniversaries = createSelector(
	(state: RootState) => state,
	(state) => {
		return getChronologicalLeadAnniversaries(state).filter(leadAnniversary => {
			const { upcomingAnniversary } = leadAnniversary;
			const difference = moment.duration(upcomingAnniversary.diff(moment())).asDays();
			return difference < 7 && difference > 0;
		});
	}
);

export const getLeadAnniversariesForLead = (state: RootState, leadId: number) => {
	const ids = state.leadAnniversaries.byLeadId[leadId];
	return getLeadAnniversariesByIds(state, ids);
};


export const getActiveLeadAnniversariesForLead = (state: RootState, leadId: number) => {
	return getLeadAnniversariesForLead(state, leadId).filter((leadAnniversary) => !leadAnniversary.deleted);
};

export const getAnniversariesForSelectedLead = (state: RootState, leadIds: number[]) => {
	return getLeadAnniversaries(state)
		.filter((leadAnniversary) => leadIds.includes(leadAnniversary.lead.id))
		.filter((leadAnniversary) => !leadAnniversary.deleted)
	;
};

// LeadImport

export const getLeadImportById = (state: RootState, id?: string): LeadImport | undefined => {
	if (id) {
		const leadImport = state.leadImports.byId[id];
		const pipelineType = LeadPipelineType.getById(leadImport.pipelineTypeId || 0);
		if (leadImport) {
			let importError: ImportError | undefined;
			if (leadImport.message === ImportError.action) {
				importError = ImportError.action;
			}
			if (leadImport.message === ImportError.import) {
				importError = ImportError.import;
			}
			if (leadImport.message === ImportError.duplicate) {
				importError = ImportError.duplicate;
			}
			let contactType: string | undefined;
			if (leadImport.tags) {
				contactType = leadImport.tags.find(tag => {
					return LeadTagType.getLeadTypeById(tag);
				});
			}
			let tags: string[] | undefined;
			if (leadImport.tags) {
				tags = leadImport.tags.filter((tag) => !LeadTagType.getById(tag));
			}
			let birthday: string | undefined;
			if (leadImport.birthday) {
				birthday = moment(leadImport.birthday).format("MM/DD/YYYY")
			}
			let transactionAnniversary: string | undefined;
			if (leadImport.transactionAnniversary) {
				transactionAnniversary = moment(leadImport.transactionAnniversary).format("MM/DD/YYYY")
			}
			return {
				id: leadImport.id,
				email: leadImport.email,
				name: leadImport.name,
				address: leadImport.address,
				state: leadImport.state,
				phone: leadImport.phone,
				city: leadImport.city,
				zip: leadImport.zip,
				birthday: birthday,
				agentId: leadImport.agentId,
				transactionAnniversary: transactionAnniversary,
				pipelineType: pipelineType ? pipelineType.label : undefined,
				contactType: contactType,
				tags: tags,
				note: leadImport.note, 
				textMessageOptIn: leadImport.textMessageOptIn,
				marketIds: leadImport.marketIds,
				campaignIds: leadImport.campaignIds,
				leadSource: leadImport.leadSource,
				error: importError,
			};
		}
	}
};

export const getLeadImportsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadImportById(state, id)).filter(Boolean) as LeadImport[];
};

export const getLeadImports = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadImports.byId).map((id) => id);
		return getLeadImportsByIds(state, ids); 
	}
);

// LeadLastActivities

export const getLeadLastActivityById = (state: RootState, id?: number): LeadLastActivity | undefined => {
	if (id) {
		const leadLastActivity = state.leadLastActivities.byId[id];
		if (leadLastActivity) {
			return {
				...leadLastActivity,
				timestamp: moment(new Date(leadLastActivity.timestamp)),
			};
		}
	}
};

export const getLeadLastActivitiesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadLastActivityById(state, id)).filter(Boolean) as LeadLastActivity[];
};

export const getLeadLastActivities = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadLastActivities.byId).map((id) => parseInt(id));
		return getLeadLastActivitiesByIds(state, ids); 
	}
);

export const getLeadLastActivityForLead = (state: RootState, leadId: number) => {
	return getLeadLastActivityById(state, leadId);
};

// LeadNotes

export const getLeadNoteById = (state: RootState, id?: number): LeadNote | undefined => {
	if (id) {
		const leadNote = state.leadNotes.byId[id];
		if (leadNote) {
			const type = LeadNoteType.getById(leadNote.typeId);
			if (type) {
				return {
					...leadNote,
					type,
					updatedOn: moment(new Date(leadNote.updatedOn)),
					createdOn: moment(new Date(leadNote.createdOn)),
				};
			}
		}
	}
};

export const getLeadNotesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadNoteById(state, id)).filter(Boolean) as LeadNote[];
};

export const getLeadNotes = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadNotes.byId).map((id) => parseInt(id));
		return getLeadNotesByIds(state, ids); 
	}
);

export const getLeadNotesForLead = (state: RootState, leadId: number) => {
	const ids = state.leadNotes.byLeadId[leadId];
	return getLeadNotesByIds(state, ids);
};

export const getActiveLeadNotesForLead = (state: RootState, leadId: number) => {
	return getLeadNotesForLead(state, leadId).filter((leadNote) => !leadNote.deleted);
};

export const getActiveLeadNotesForLeadChronological = (state: RootState, leadId: number) => {
	return getActiveLeadNotesForLead(state, leadId).sort((note1: LeadNote, note2: LeadNote) => {
		return note2.updatedOn.valueOf() - note1.updatedOn.valueOf()
	})
};

export const getCallNotes = createSelector(
	(state: RootState) => state,
	(state) => {
		return getLeadNotes(state).filter(note => note.type === LeadNoteType.PHONE_CALL);
	}
);

// LeadPhones

export const getLeadPhoneById = (state: RootState, id?: number): LeadPhone | undefined => {
	if (id) {
		const leadPhone = state.leadPhones.byId[id];
		if (leadPhone) {
			const type = LeadPhoneType.getById(leadPhone.typeId);
			if (type) {
				return {
					...leadPhone,
					type,
					updatedOn: moment(new Date(leadPhone.updatedOn)),
					createdOn: moment(new Date(leadPhone.createdOn))
				};
			}
		}
	}
};

export const getLeadPhonesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadPhoneById(state, id)).filter(Boolean) as LeadPhone[];
};

export const getLeadPhones = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadPhones.byId).map((id) => parseInt(id));
		return getLeadPhonesByIds(state, ids); 
	}
);

export const getLeadPhonesForLead = (state: RootState, leadId: number) => {
	const ids = state.leadPhones.byLeadId[leadId];
	return getLeadPhonesByIds(state, ids);
};

export const getActiveLeadPhonesForLead = (state: RootState, leadId: number) => {
	return getLeadPhonesForLead(state, leadId).filter((leadPhone) => !leadPhone.deleted);
};

// LeadPipelines

export const getLeadPipelineById = (state: RootState, id?: number): LeadPipeline | undefined => {
	if (id) {
		const leadPipeline = state.leadPipelines.byId[id];
		if (leadPipeline) {
			const type = LeadPipelineType.getById(leadPipeline.typeId);
			if (type) {
				return {
					...leadPipeline,
					type,
					createdOn: moment(new Date(leadPipeline.createdOn)),
				};
			}
		}
	}
};

export const getLeadPipelinesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadPipelineById(state, id)).filter(Boolean) as LeadPipeline[];
};

export const getLeadPipelines = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadPipelines.byId).map((id) => parseInt(id));
		return getLeadPipelinesByIds(state, ids); 
	}
);

export const getChronologicalLeadPipelinesForLead = (state : RootState, leadId: number) => {
	return getLeadPipelinesForLead(state, leadId).sort((a, b) =>
		a.createdOn < b.createdOn ? 1 : -1,
	);
};

export const getLatestLeadPipelineForLead = (state: RootState, leadId: number) => {
	return getChronologicalLeadPipelinesForLead(state, leadId).shift();
};

export const getLatestLeadPipelinesForLeads = (state: RootState, leadIds: number[]) => {
	return leadIds.map(leadId => getLatestLeadPipelineForLead(state, leadId))
};

export const getLeadPipelinesForLead = (state: RootState, leadId: number) => {
	const ids = state.leadPipelines.byLeadId[leadId];
	return getLeadPipelinesByIds(state, ids);
};

// Leads

export const getLeadById = (state: RootState, id?: number): Lead | undefined => {
    if (!id || !state.leads.byId[id]) {
        return undefined;
    }
	if (id) {
		const lead = state.leads.byId[id];
		if (lead) {
			const status = LeadStatus.getById(lead.status);
			const agent = getAgentById(state, lead.agentId);
			let lastActiveOn: Moment | undefined;
			{
				const leadLastActivity = getLeadLastActivityForLead(state, lead.id);
				if (leadLastActivity) {
					lastActiveOn = leadLastActivity.timestamp;
				}
			}
			const pipeline = getLatestLeadPipelineForLead(state, lead.id);
			if (status && lead.createdOn) {
				return {
					...lead,
					label: lead.name && lead.name.trim() ? lead.name.trim() : lead.email,
					createdOn: moment(new Date(lead.createdOn)),
					lastActiveOn,
					agent,
					status,
					get subscribed() {
						let subscribed = false;
						if (!subscribed) {
							subscribed = getActiveSavedSearchesForLead(state, lead.id).length > 0;
						}
						if (!subscribed) {
							subscribed = getActiveMarketReportSubscriptionsForLead(state, lead.id).length > 0;
						}
						if (!subscribed) {
							subscribed = getActiveDripCampaignSubscriptionsForLead(state, lead.id).length > 0;
						}
						return subscribed;
					},
					pipeline,
				};
			}
		}
	}
};

export const getLeadsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getLeadById(state, id)).filter(Boolean) as Lead[];
};

export const getLeads = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leads.byId).map((id) => parseInt(id));
		return getLeadsByIds(state, ids); 
	}
);

export const getActiveLeads = createSelector(
	(state: RootState) => state,
	(state) => {
		return getLeads(state).filter(lead => lead.status.active);
	}
);

export const getActiveLeadsByAgentId = (state: RootState, agentId: number) => {
		return getLeads(state).filter(lead => lead.status.active && lead.agent?.id === agentId);
	};

export const getLeadsById = (state: RootState, leadIds: number[]) => {
	return getLeads(state).filter(lead => leadIds.includes(lead.id));
};

export const getLeadsByAgentId = (state: RootState, agentId: number) => {
	return getLeads(state).filter(lead => lead.agent?.id === agentId);
};

export const getLeadsByUnassignedAgent = (state: RootState) => {
	return getLeads(state).filter(lead => !lead.agent);
};

export const getActiveLeadsWithinMonth = createSelector(
	(state: RootState) => state,
	(state) => {
		return getActiveLeads(state).filter(lead => lead.createdOn > moment().subtract(1, "M"));
	}
);

export const getFilteredLeads = createSelector(
	(state: RootState) => state,
	(state) => {
		let leads = getLeads(state);
		const filters = getLeadsPageFilters(state);
		{
			const values = filters.agent;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						if (lead.agent) {
							return lead.agent.id === value;
						}
						if (value === -1) {
							return !lead.agent;
						}
					});
				});
			}
		}
		{
			const values = filters.type;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						return getLeadTagByText(state, lead.id, value);
					});
				});
			}
		}
		{
			const values = filters.status;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						return lead.status.id === value;
					});
				});
			}
		}
		{
			const value = filters.bookmarked;
			if (value) {
				leads = leads.filter((lead) => {
					return getBookmarkTagForLead(state, lead.id);
				});
			}
		}
		{
			const value = filters.subscribed;
			if (value) {
				leads = leads.filter((lead) => {
					return lead.subscribed;
				});
			}
		}
		{
			const values = filters.ranking;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					let ranking = LeadRanking.NO_RANKING;
					let allLeads = getActiveLeads(state).sort(function(lead1, lead2) {
						return (lead1.ranking || 0) > (lead2.ranking || 0) ? -1 : 1;
					});
					let rating = 0;
					let ratingSize = 0;
					allLeads.forEach(function(lead) {
						if (lead.ranking && lead.ranking > 0) {
							ratingSize = ratingSize + 1;
						}
					});
					allLeads.forEach(function(lead2) {
						rating++;
						if (lead.id === lead2.id && lead2.ranking && lead2.ranking > 0) {
							if (rating > (ratingSize / 3) * 2) {
								ranking = LeadRanking.LOW;
							} else if (rating > ratingSize / 3) {
								ranking = LeadRanking.MEDIUM;
							} else {
								ranking = LeadRanking.HIGH;
							}
						}
					});
					return values.some((value) => {
						return ranking.id === value;
					});
				});
			}
		}
		{
			const values = filters.source;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						return lead.source === value;
					});
				});
			}
		}
		{
			const values = filters.pipeline;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						return lead.pipeline && lead.pipeline.type.id === value;
					});
				});
			}
		}
		{
			const values = filters.tag;
			if (values && values.length) {
				leads = leads.filter((lead) => {
					return values.some((value) => {
						return getLeadTagByText(state, lead.id, value);
					});
				});
			}
		}
		return leads;
	}
);

export const getActiveFilteredLeads = createSelector(
	(state: RootState) => state, 
	(state) => {
		return getFilteredLeads(state).filter((lead: Lead) => lead.status === LeadStatus.ACTIVE);
	}
);

export const getLeadsSources = createSelector(
	(state: RootState) => state,
	(state) => {
		let leadSources: string[] = [];
		getLeads(state).forEach((lead) => {
			if (lead.source) {
				const isAdded = leadSources.some((source) => source === lead.source);
				if (!isAdded) {
					leadSources.push(lead.source);
				}
			}
		});
		return leadSources;
	}
);

export const getLeadsWithTasks = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Array.from(new Set(getTasks(state).map((task) => task.leadId).filter(Boolean) as number[]));
		return getLeadsByIds(state, ids);
	}
);

export const getActiveLeadEmails = createSelector(
	(state: RootState) => state, 
	(state) => {
		return getLeads(state).filter((lead: Lead) => lead.status === LeadStatus.ACTIVE).map((lead: Lead) => lead.email);
	}
);

export const getNonActiveLeadEmails = createSelector(
	(state: RootState) => state, 
	(state) => {
		return getLeads(state).filter((lead: Lead) => lead.status !== LeadStatus.ACTIVE).map((lead: Lead) => lead.email);
	}
);

// LeadsPage

export const getLeadsPageFilters = (state: RootState): LeadsPageFilters => {
	return state.leadsPage.filters;
}

export const getLeadsPageFiltersCount = (state: RootState) => {
	let count = 0;
	const filters = getLeadsPageFilters(state);
	Object.keys(filters).forEach((name) => {
		// @ts-ignore
		const value = filters[name];
		if (value instanceof Array) {
			count += value.length;
		} else if (!!value) {
			count++;
		}
	})
	return count;
}

export const getLeadsPageSort = (state: RootState): Sort => {
	if (state.leadsPage.sort) {
		return state.leadsPage.sort;
	} else {
		return {
			id: "createdOn", 
			desc: true,
		};
	}
	
}

export const getLeadsPagePageSize = (state: RootState): number => {
	return state.leadsPage.pageSize;
}

export const getLeadsPagePage = (state: RootState): number => {
	return state.leadsPage.page;
}

export const getLeadsPageColumns = (state: RootState): LeadColumnType[] => {
	return state.leadsPage.columnTypeIds.map((id) => {
		return LeadColumnType.getById(id);
	}).filter(Boolean) as LeadColumnType[];
}

// LeadTags

export const getTagById = (state: RootState, id?: number): LeadTag | undefined => {
	if (id) {
		const leadTag = state.leadTags.byId[id];
		if (leadTag) {
			const defaultType = LeadTagType.getById(leadTag.text);
			return {
				...leadTag,
				defaultType,
				createdOn: moment(new Date(leadTag.createdOn)),
				updatedOn: moment(new Date(leadTag.updatedOn)),
			};
		}
	}
};

export const getTagsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getTagById(state, id)).filter(Boolean) as LeadTag[];
};

export const getTags = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.leadTags.byId).map((id) => parseInt(id));
		return getTagsByIds(state, ids); 
	}
);

const getCustomTags = createSelector(
	(state: RootState) => state,
	(state) => {
		return getTags(state).filter((tag) => !tag.defaultType);
	}
);

export const getTagsForLead = (state: RootState, leadId: number) => {
	const ids = state.leadTags.byLeadId[leadId];
	return getTagsByIds(state, ids);
};

export const getTagsForLeads = (state: RootState, leadIds: number[]) => {
	return getTags(state).filter(leadTag => leadIds.includes(leadTag.leadId));
};

export const getCustomTagsForLead = (state: RootState, leadId: number) => {
	return getTagsForLead(state, leadId).filter((tag) => !tag.defaultType);
};

export const getTypeTagsForLead = (state: RootState, leadId: number) => {
	return getTagsForLead(state, leadId).filter((tag) => tag.defaultType && tag.defaultType.type);
};

export const getBookmarkTagForLead = (state: RootState, leadId: number) => {
	return getTagsForLead(state, leadId).find((tag) => tag.defaultType && tag.defaultType.bookmarked);
};

export const getLeadTagByText = (state: RootState, leadId: number, text: string) => {
	return getTagsForLead(state, leadId).find((tag) => tag.text.toLowerCase() === text.toLowerCase());
};

export const getCustomTagTexts = createSelector(
	(state: RootState) => state,
	(state) => {
		const results = new Set<string>();
		getCustomTags(state).forEach((tag) => results.add(tag.text));
		return Array.from(results);
	}
);

export const getAllTagStrings = createSelector(
	(state: RootState) => state,
	(state) => {
		const customTags = getCustomTagTexts(state);
		let texts = [
			...customTags,
			...LeadTagType.values().map(type => type.label),
			"Lead",
			"Prospect",
			"Active Client",
			"Past Client",
			"Buyer Agent",
			"Seller Agent",
			"Mortgage Brokers",
			"Loan Officer",
			"Residential",
			"Commercial",
			"Land",
			"Pre-Approved",
			"Has Loan",
			"Made Offer",
			"Closing Scheduled",
			"Has Active Listing",
			"Attended Showing",
			"Pre-Qualified $150K",
			"Pre-Qualified $200K",
			"Pre-Qualified $250K",
			"Pre-Qualified $300K",
			"Pre-Qualified $350K",
			"Pre-Qualified $400K",
			"Pre-Qualified $450K",
			"Pre-Qualified $500K",
			"Pre-Qualified $550K",
			"Pre-Qualified $600K",
			"Pre-Qualified $650K",
			"Pre-Qualified $700K",
			"Pre-Qualified $750K",
			"Pre-Qualified $800K",
			"Pre-Qualified $850K",
			"Pre-Qualified $900K",
			"Pre-Qualified $950K",
			"Pre-Qualified $1M",
			"Pre-Qualified $1-1.5M",
			"Pre-Qualified $1.5-2M",
			"Pre-Qualified $2-2.5M",
			"Pre-Qualified $2.5-3M",
			"Pre-Qualified $3M+",
		];
		return Array.from(new Set(texts));
	}
);

// Listings

export const getListingById = (state: RootState, id?: string): Listing | undefined => {
	if (id) {
		const listing = state.listings.byId[id];
		if (listing) {
			return {
				...listing,
				get propertyType() {
					return PropertyType.getById(listing.propertyType);
				},
				get address() {
					const parts = listing.address.split("\\n");
					const result: [string, string] = [
						(parts[0] || "").trim().toUpperCase(),
						(parts[1] || "").trim().toUpperCase(),
					];
					return result;
				},
				get addressOneLine() {
					return this.address.join(" ");
				},
				get bathrooms() {
					const result: number[] = [];
					let { fullBathrooms, partialBathrooms } = listing;
					if (fullBathrooms) {
						result.push(fullBathrooms);
					}
					if (!fullBathrooms && partialBathrooms) {
						result.push(0);
					}
					if (partialBathrooms) {
						result.push(partialBathrooms);
					}
					return result.join(" | ");
				},
				get priceLabel() {
					return formatPrice(listing.price);
				},
			};
		}
	}
};

export const getListingsByIds = (state: RootState, ids?: string[]) => {
	ids = ids || [];
	return ids.map((id) => getListingById(state, id)).filter(Boolean) as Listing[];
};

export const getListings = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.listings.byId);
		return getListingsByIds(state, ids); 
	}
);


// Markets

export const getMarketById = (state: RootState, id: number): Market | undefined => {
	if (id) {
		const market = state.markets.byId[id];
		if (market && market.criteria) {
			return {
				id: market.id,
				photoUrl: market.photoUrl,
				name: market.name,
				criteria: market.criteria,
				header: market.header,
				footer: market.footer,
				subhead: market.subhead,
				description: market.description,
				sortOrder: market.sortOrder,
				showOnIndex: market.showOnIndex,
				listingReportSupported: market.listingReportSupported || false, 
				openHomesReportSupported: market.openHomesReportSupported || false, 
				marketReportSupported: market.marketReportSupported || false, 
				inactive: market.inactive || false,
				get reports() {
					return getMarketReportsByMarketId(state, market.id)
				},
				get listingReport() {
					return this.reports.find((report) => report.type.listing);
				},
				get openHomesReport() {
					return this.reports.find((report) => report.type.openHomes);
				},
				get marketReport() {
					return this.reports.find((report) => report.type.market);
				},
				get marketReportSubscriptions() {
					let subscriptions: MarketReportSubscription[] = [];
					this.reports.forEach((report) => {
						if (report.subscriptions) {
							subscriptions = [...subscriptions, ...report.subscriptions];
						}
					});
					return subscriptions;
				},
				get marketReportTypes() {
					return [
						this.listingReport && market.listingReportSupported && this.listingReport.type,
						this.openHomesReport && market.openHomesReportSupported && this.openHomesReport.type,
						this.marketReport && market.marketReportSupported && this.marketReport.type,
					  ].filter(Boolean) as MarketReportType[];
				}
			}
		}
	}
}

export const getMarketsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getMarketById(state, id)).filter(Boolean) as Market[];
}

export const getMarkets = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.markets.byId).map((id) => parseInt(id));
		return getMarketsByIds(state, ids); 
	}
);

// market settings

export const getmarketSettings = (state: RootState): MarketSettings | undefined => {
	const marketSettingsState = state.marketSettings;
	if (marketSettingsState === undefined) {
		return;
	}
	if (marketSettingsState) {
		return {
			listingCarouselColumns: marketSettingsState.listingCarouselColumns,
			communitiesLayout: marketSettingsState.communitiesLayout
		};
	}
};

// MarketListings

export const getMarketListingById = (state: RootState, id?: number): MarketListing | undefined => {
	if (id) {
		const marketListing = state.marketListings.byId[id];
		if (marketListing) {
			return {
				...marketListing,
				listing: getListingById(state, marketListing.listing),
			}
		}
	}
};

export const getMarketListingsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getMarketListingById(state, id)).filter(Boolean) as MarketListing[];
};

export const getMarketListings = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.marketListings.byId).map((id) => parseInt(id));
		return getMarketListingsByIds(state, ids); 
	}
);
export const getMarketListingByMarketId = (state: RootState, id: number) => {
	return getMarketListings(state).filter((value) => value.marketId === id); 
};

// MarketReport 

export const getMarketReportById = (state: RootState, id: number): MarketReport | undefined => {
	const marketReport = state.marketReports.byId[id];
	if (marketReport) {
		const type = MarketReportType.getById(marketReport.typeId);
		if (type) {
			return {
				...marketReport,
				type,
				customUrl: marketReport.customUrl,
				get market() {
					return getMarketById(state, marketReport.marketId);
				},
				get subscriptions() {
					return getMarketReportSubscriptionsForMarketReport(state, marketReport.id);
				},
				inactive: marketReport.inactive,
			}
		}
	}
}

export const getMarketReportsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getMarketReportById(state, id)).filter(Boolean) as MarketReport[];
}

export const getMarketReports = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.marketReports.byId).map((id) => parseInt(id));
		return getMarketReportsByIds(state, ids); 
	}
);

export const getMarketReportsByMarketId = (state: RootState, id: number) => {
	return getMarketReports(state).filter(report => {
		if (report.market && report.market.id === id) {
			if ( (report.type.listing) || 
				(report.type.market && report.market.marketReportSupported) || 
				(report.type.openHomes && report.market.openHomesReportSupported) 
			) {
				return true;
			}
		}
		return false;
	})
};

export const getUnsubscribedReportsByLeadId = (state: RootState, leadId: number) => {
	const reports = getMarketReports(state);
	const subscriptions = getMarketReportSubscriptionsForLead(state, leadId);
	let subscribedReportIds: number[] | undefined;
	let unsubscribedReports: MarketReport[] | undefined;
	if (subscriptions) {
		subscribedReportIds = subscriptions.map(subscription => subscription.report.id)
	}
	if (reports) {
		unsubscribedReports = reports.filter(report => {
			if (subscribedReportIds && subscribedReportIds.includes(report.id)) {
				return false;
			}
			return true;
		})
	}
	return unsubscribedReports;
};

export const getUnsubscribedMarketReportsByMarketId = (state: RootState, marketId: number, leadId: number) => {
	const subscriptions = getMarketReportSubscriptionsForLead(state, leadId);
	const market = getMarketById(state, marketId);
	let subscribedReportIds: number[] | undefined;
	let reports: MarketReport[] | undefined;
	let unsubscribedReport: MarketReport[] | undefined;
	if (subscriptions) {
		subscribedReportIds = subscriptions.map(subscription => subscription.report.id)
	}
	if (market && market.reports) {
		reports = market.reports;
		unsubscribedReport = reports.filter(report => {
			if (subscribedReportIds && subscribedReportIds.includes(report.id)) {
				return false;
			}
			return true;
		})
	}
	return unsubscribedReport;
};

// MarketReportSubscriptions

export const getMarketReportSubscriptionById = (state: RootState, id: number): MarketReportSubscription | undefined => {
	if (id) {
		const subscription = state.marketReportSubscriptions.byId[id];
		if (subscription) {
			const report = getMarketReportById(state, subscription.marketReportId);
			const lead = getLeadById(state, subscription.leadId);
			if (report && lead) {
				return {
					...subscription,
					lead,
					report,
					createdOn: subscription.createdOn ? moment(new Date(subscription.createdOn)) : undefined,
					get emailSents() {
						return getEmailSentsForLeadAndMarketReport(state, subscription.leadId, subscription.marketReportId);
					},
					get emailClicks() {
						return getEmailClicksForLeadAndMarketReport(state, subscription.leadId, subscription.marketReportId);
					},
				}
			}
		}
	}
};

export const getMarketReportSubscriptionsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getMarketReportSubscriptionById(state, id)).filter(Boolean) as MarketReportSubscription[];
};

export const getMarketReportSubscriptions = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.marketReportSubscriptions.byId).map((id) => parseInt(id));
		return getMarketReportSubscriptionsByIds(state, ids);
	}
);

export const getMarketReportSubscriptionsForLead = (state: RootState, id: number) => {
	const ids = state.marketReportSubscriptions.byLeadId[id];
	return getMarketReportSubscriptionsByIds(state, ids);
};

export const getMarketReportSubscriptionsForMarketReport = (state: RootState, id: number) => {
	return getMarketReportSubscriptions(state).filter((marketReportSubscription) => marketReportSubscription.report.id === id);
};

export const getActiveMarketReportSubscriptionsForLead = (state: RootState, id: number) => {
	return getMarketReportSubscriptionsForLead(state, id).filter((subscription) => !subscription.inactive);
};

// MobileDialogDate

export const getMobileDialogDate = (state: RootState): Moment | undefined => {
	if (state.mobileDialogDate.date) {
		return moment(state.mobileDialogDate.date);
	} 
}

export const getMobileDialogClickLogin = (state: RootState): boolean | undefined => {
	if (state.mobileDialogDate.clickMobileLogin) {
		return state.mobileDialogDate.clickMobileLogin;
	} 
}

// Neighborhoods

export const getNeighborhoodById = (state: RootState, id: number): Neighborhood | undefined => {
	if (id) {
		const neighborhood = state.neighborhoods.byId[id];
		if (neighborhood) {
			return {
				...neighborhood,
			}
		}
	}
};

export const getNeighborhoodsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getNeighborhoodById(state, id)).filter(Boolean) as Neighborhood[];
};

export const getNeighborhoods = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.neighborhoods.byId).map((id) => parseInt(id));
		return getNeighborhoodsByIds(state, ids); 
	}
);

// OfficeBoards

export const getOfficeBoardById = (
	state: RootState,
	id?: number
): OfficeBoard | undefined => {
	if (id) {
		const officeBoard = state.officeBoards.byId[id];
		if (officeBoard) {
			return {
				...officeBoard,
			};
		}
	}
};

export const getOfficeBoardsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids
		.map((id) => getOfficeBoardById(state, id))
		.filter(Boolean) as OfficeBoard[];
};

export const getOfficeBoards = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.officeBoards.byId).map((id) => parseInt(id));
		return getOfficeBoardsByIds(state, ids);
	}
);

export const getOfficeBoardsForOffice = (state: RootState, officeId: number) => {
	const ids = state.officeBoards.byOfficeId[officeId];
	return getOfficeBoardsByIds(state, ids);
};

// Offices

export const getOfficeById = (state: RootState, id?: number): Office | undefined => {
	if (id) {
		const office = state.offices.byId[id];
		if (office) {
			return {
				...office,
				name: (office.name) ? office.name : '',			
				address: (office.address) ? office.address : '',
				city: (office.city) ? office.city : '',
				priority: (office.priority) ? office.priority : 0,
			}
		}
	}
};

export const getOfficesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getOfficeById(state, id)).filter(Boolean) as Office[];
};

export const getOffices = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.offices.byId).map((id) => parseInt(id));
		return getOfficesByIds(state, ids); 
	}
);

// OneUsers 


export const getOneUser = (state: RootState) => {
	return state.oneUsers.user;
}

// Pages 

export const getPageById = (state: RootState, id?: number): Page | undefined => {
	if (id) {
		const page = state.pages.byId[id];
		if (page) {
			const type = PageType.getById(page.typeId);
			if (type) {
				return {
					...page,
					type,
				}
			}
		}
	}
};

export const getPagesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getPageById(state, id)).filter(Boolean) as Page[];
};

export const getPagesByTypes = (state: RootState, pageTypes?: PageType[]) => {
	pageTypes = pageTypes || [];
	return pageTypes.map((pageType) => getPageByTypeId(state, pageType.id)).filter(Boolean) as Page[];
};

export const getPageByTypeId = (state: RootState, id: number) => {
	return getPages(state).find((page) => page.type.id === id);
};

export const getPages = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.pages.byId).map((id) => parseInt(id));
		return getPagesByIds(state, ids); 
	}
);

// Quota

export const getQuota = createSelector(
	(state: RootState) => state,
	(state) => {
		const quota = state.quota.quota;
		if (quota) {
			return {
				...quota,
				blastCampaignMessageQueueRemaining: quota.blastCampaignMessageQueueLimit - quota.blastCampaignMessageQueueCount,
			} as Quota;
		}
	}
);

// Resellers 

export const getResellerById = (state: RootState, id?: number): Reseller | null | undefined => {
	if (id) {
		const reseller = state.resellers.byId[id];
		if (reseller) {
			return {
				...reseller,
			};
		}
	}
}

// SavedListing

export const getSavedListingById = (state: RootState, id?: number): SavedListing | undefined => {
	if (id) {
		const savedListing = state.savedListings.byId[id];
		if (savedListing) {
			const lead = getLeadById(state, savedListing.leadId);
			if (lead) {
				return {
					...savedListing,
					lead,
					listing: getListingById(state, savedListing.listing),
					createdOn: moment(new Date(savedListing.createdOn)),
					modifiedOn: savedListing.modifiedOn ? moment(new Date(savedListing.modifiedOn)) : undefined,
					deleted: savedListing.deleted ? savedListing.deleted : false,
				}
			}
		}
	}
};

export const getSavedListingsByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getSavedListingById(state, id)).filter(Boolean) as SavedListing[];
};

export const getSavedListings = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.savedListings.byId).map((id) => parseInt(id));
		return getSavedListingsByIds(state, ids); 
	}
);

export const getSavedListingsForLead = (state: RootState, leadId: number) => {
	const ids = state.savedListings.byLeadId[leadId];
	return getSavedListingsByIds(state, ids);
};

export const getActiveSavedListingsForLead = (state: RootState, leadId: number) => {
	return getSavedListingsForLead(state, leadId)
		.filter(savedListing => savedListing.listing)
		.filter((savedListing) => !savedListing.deleted)
	;
};

// SavedSearches

export const getSavedSearchById = (state: RootState, id?: number): SavedSearch | undefined => {
	if (id) {
		const savedSearch = state.savedSearches.byId[id];
		if (savedSearch) {
			return {
				...savedSearch,
				get label() {
					return savedSearch.name ? savedSearch.name : `Saved Search ${numberedDateFormatter(this.createdOn)}`;
				},
				criteria: savedSearch.criteria,
				createdOn: moment(new Date(savedSearch.createdOn)),
				modifiedOn: savedSearch.modifiedOn ? moment(new Date(savedSearch.modifiedOn)) : undefined,
				get emailSents() {
					return getEmailSentsForSavedSearch(state, savedSearch.id);
				},
				get emailClicks() {
					return getEmailClicksForSavedSearch(state, savedSearch.id);
				},
				get resultCount() {
					return getSavedSearchResultTotalForSavedSearch(state, savedSearch.id);
				},
			}
		}
	}
};

export const getSavedSearchesByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getSavedSearchById(state, id)).filter(Boolean) as SavedSearch[];
};

export const getSavedSearches = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.savedSearches.byId).map((id) => parseInt(id));
		return getSavedSearchesByIds(state, ids); 
	}
);

export const getSavedSearchesForLead = (state: RootState, leadId: number) => {
	const ids = state.savedSearches.byLeadId[leadId];
	return getSavedSearchesByIds(state, ids);
};

export const getActiveSavedSearchesForLead = (state: RootState, leadId: number) => {
	return getSavedSearchesForLead(state, leadId).filter((savedSearch) => {
		return !savedSearch.inactive;
	});
};

// SavedSearchResultTotals

const getSavedSearchResultTotalById = (state: RootState, id?: number): number | undefined => {
	if (id) {
		const savedSearchResultTotal = state.savedSearchResultTotals.byId[id];
		if (savedSearchResultTotal) {
			return savedSearchResultTotal.total;
		}
	}
}

export const getSavedSearchResultTotalForSavedSearch = (state: RootState, savedSearchId: number) => {
	return getSavedSearchResultTotalById(state, savedSearchId);
}

// SidebarMenu 

export const getActive = (state: RootState) => {
	return state.sidebarMenu.active;
}

export const getOpen = (state: RootState) => {
	return state.sidebarMenu.open;
}

// SiteLinks

export const getSiteLinkById = (state: RootState, id: string): SiteLink | undefined => {
	if (id) {
		const SiteLink = state.siteLinks.byId[id];
		if (SiteLink) {
			const linkType = SiteLinkType.getById(id);
			if (linkType) {
				return {
					id: SiteLink.id,
					url: SiteLink.url,
					type: linkType,
					group: linkType.group,
				}
			}
		}
	}
}

export const getSiteLinks = (state: RootState) => {
	return Object.keys(state.siteLinks.byId)
	.map((id) => getSiteLinkById(state, id))
	.filter(Boolean) as SiteLink[];
};

// Tasks

export const getTaskById = (state: RootState, id?: number): Task | undefined => {
	if (id) {
		const task = state.tasks.byId[id];
		if (task) {
			const type = task.taskTypeId ? TaskType.getById(task.taskTypeId) : undefined;
			const status = TaskStatus.getById(task.taskStatusTypeId);
			if (status && type) {
				return {
					...task,
					type,
					status,
					startOn: moment(new Date(task.startOn)),
					endOn: moment(new Date(task.endOn)),
					updatedOn: moment(new Date(task.updatedOn)),
					createdOn: moment(new Date(task.createdOn)),
				};
			}
		}
	}
};

export const getTasksByIds = (state: RootState, ids?: number[]) => {
	ids = ids || [];
	return ids.map((id) => getTaskById(state, id)).filter(Boolean) as Task[];
};

export const getTasks = createSelector(
	(state: RootState) => state,
	(state) => {
		const ids = Object.keys(state.tasks.byId).map((id) => parseInt(id));
		return getTasksByIds(state, ids); 
	}
);

export const getTasksForLead = (state: RootState, leadId: number) => {
	const ids = state.tasks.byLeadId[leadId];
	return getTasksByIds(state, ids);
};

export const getOpenTasks = createSelector(
	(state: RootState) => state,
	(state) => {
		return getTasks(state).filter(task => {
			return task.status.active || (task.status.complete && task.updatedOn >= moment().subtract(2, "minutes"));
		});
	}
);

export const getOpenTasksForLead = (state: RootState, leadId: number) => {
	return getOpenTasks(state).filter((task) => task.leadId === leadId);
};

export const getChronologicalActiveTasks = createSelector(
	(state: RootState) => state,
	(state) => {
		return getOpenTasks(state).sort((task1, task2) => task1.endOn.valueOf() - task2.endOn.valueOf());
	}
);

export const getActiveLeadTasksDueThisWeek = createSelector(
	(state: RootState) => state,
	(state) => {
		return getChronologicalActiveTasks(state).filter(task => task.endOn < moment().add("w", 1));
	}
);

export const getFilteredTasks = createSelector(
	(state: RootState) => state,
	(state) => {
		let tasks = getTasks(state);
		const filters = getTasksPageFilters(state);
		{
			const values = filters.agent;
			if (values && values.length) {
				tasks = tasks.filter((task) => {
					return values.some((value) => {
						if (task.agentId) {
							return task.agentId === value;
						} else {
							return false;
						}
					});				
				});
			}
		}
		{
			const values = filters.type;
			if (values && values.length) {
				tasks = tasks.filter((task) => {
					return values.some((value) => {
						if (task.type) {
							return task.type.id === value;
						} else {
							return false;
						}
					});				
				});
			}
		}
		{
			const values = filters.status;
			if (values && values.length) {
				const activeFilterIsSet = values.find(value => value === TaskStatus.ACTIVE.id)
				tasks = tasks.filter((task) => {
					return (
						// recentlyCompleted
						(activeFilterIsSet &&
							task.status === TaskStatus.COMPLETE &&
							task.updatedOn >= moment().subtract(2, "minutes")) ||
						// status
						values.some(value => {
							return task.status.id === value; 
						})
					)
				});
			}
		}
		{
			const value = filters.priority;
			if (value) {
				tasks = tasks.filter((task) => {
					return task.priority === value;
				});
			}
		}
		{
			const value = filters.today;
			if (value) {
				tasks = tasks.filter((task) => {
					const endOn = task.endOn;
					const today = moment();
					if (endOn.format("YYYYMMDD") <= today.format("YYYYMMDD")) {
						return true;
					} else {
						return false;
					}
				});
			}
		}
		{
			const value = filters.lead;
			if (value) {
				tasks = tasks.filter((task) => {
					return task.leadId === value;
				});
			}
		}
		return tasks;
	}
);

export const getFilteredTasksByAgentId = (state: RootState, agentId: number | undefined) => {
	return getFilteredTasks(state).filter(task => task.agentId === agentId)
}

// TasksPage 

export const getTasksPageFilters = (state: RootState): TasksPageFiltersState => {
	return state.taskPage.filters;
}

export const getTasksPageFiltersCount = (state: RootState) => {
	let count = 0;
	const filters = getTasksPageFilters(state);
	Object.keys(filters).forEach((name) => {
		// @ts-ignore
		const value = filters[name];
		if (value instanceof Array) {
			count += value.length;
		} else if (!!value) {
			count++;
		}
	})
	return count;
}

// UserAuth

export const getUserAuthError = (state: RootState) => {
	return state.userAuth.error;
}

export const getUserAuth = (state: RootState): UserAuth | undefined => {
	if (state.authentication.user) {
		return {
			username: state.authentication.user.username
		}
	} else {
		return;
	}
};
