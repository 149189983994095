class Type {
	public static OFF = new Type(
		0,
		"Off, do not include an option to search for Open Houses"
	);
	public static OWN_LISTINGS = new Type(
		1,
		"On, but only include my own listings in the search results"
	);
	public static ANY_LISTINGS = new Type(
		2,
		"On, include any IDX listing in the search results"
	);

	private constructor(
		public readonly id: number,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.OFF;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as OpenHouseSearchType };
